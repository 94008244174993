// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".oD898._1e3rz{visibility:hidden}.oD898 *{-webkit-box-sizing:border-box;box-sizing:border-box}.oD898 .B6Dc2{font-size:14px;font-size:.875rem;line-height:20px;color:var(--font-color);letter-spacing:normal}.oD898 h1,.oD898 h2,.oD898 h3,.oD898 h4,.oD898 h5,.oD898 h6,.oD898 strong{font-weight:500;color:var(--font-color)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dialog": "oD898",
	"hidden": "_1e3rz",
	"dialogContent": "B6Dc2"
};
module.exports = ___CSS_LOADER_EXPORT___;
