import App from "./components/App";

import messages from "./config/constants/messages";
import titles from "./config/constants/titles";
import routes from "./config/constants/routes";
import menus from "./config/constants/menus";
import labels from "./config/constants/labels";

import {runPostTypesConfigSync} from "./postTypes";

export default function setContents(p = {}) {

    const {wapp} = p;

    function getTitle({wapp, res, title = titles.homeTitle}) {
        const config = wapp.getTargetObject().config;
        const {siteName = "Wapplr"} = config;
        const {statusCode, statusMessage, errorMessage} = res.wappResponse;
        if (statusCode === 404) {
            title = statusMessage || "Not found";
        }
        if (statusCode === 500) {
            title = errorMessage || statusMessage || "Internal Server Error";
        }
        return title + " | " + siteName;
    }

    wapp.contents.add({
        "home": {
            render: App,
            renderType: "react",
            title: function (p) {
                return getTitle({...p, title: titles.homeTitle})
            }
        }
    });

    wapp.router.replace([
        {path: "/" /*,...redirectToReadmeMd*/, contentName: "home"},
    ]);

    /*contents for post types, post, document, user...*/

    runPostTypesConfigSync({action:"setContents", p:{wapp, messages, routes, titles, menus, labels, getTitle}});

    /*contents for user account*/

    wapp.contents.add({
        account: {
            render: App,
            renderType: "react",
            title: function (p) {

                const wappResponse = p.res.wappResponse;
                const route = wappResponse.route;
                const {params} = route;

                let title;

                switch (params.page) {
                    case "changedata":
                        title = titles.changeDataTitle;
                        break;
                    case "changeemail":
                        title = titles.changeEmailTitle;
                        break;
                    case "changepassword":
                        title = titles.changePasswordTitle;
                        break;
                    case "forgotpassword":
                        title = titles.forgotPasswordTitle;
                        break;
                    case "deleteaccount":
                        title = titles.deleteAccountTitle;
                        break;
                    case "logout":
                        title = titles.logoutTitle;
                        break;
                    default:
                        title = titles.accountTitle
                }

                return getTitle({...p, title})
            }
        },
    });

    wapp.router.add([
        {path: routes.accountRoute, contentName: "account"},
        {path: routes.accountRoute+"/:page", contentName: "account"},
        {path: routes.accountRoute+"/*", contentName: "account"},
    ]);

}

export async function contentsMiddleware(req) {

    const wapp = req.wapp;

    if (!wapp.contents.contentManager.firstRequest) {
        wapp.contents.contentManager.firstRequest = async function ({req, res}) {

            const footerMenu = res.wappResponse.store.getState("res.responses.footerMenu");

            if (!footerMenu) {

                const response = await wapp.requests.send({
                    requestName: "documentFindManyByTags",
                    args: {
                        tags: ["footer-menu"]
                    },
                    req,
                    res
                });

                if (response && response["documentFindManyByTags"]) {

                    res.wappResponse.store.dispatch(wapp.states.stateManager.actions.res({
                        type: "INS_RES",
                        name: "responses",
                        value: {"footerMenu": response["documentFindManyByTags"], "documentFindManyByTags": null}
                    }));

                }

            }

            const homeServices = res.wappResponse.store.getState("res.responses.homeServices");

            if (!homeServices) {

                const response = await wapp.requests.send({
                    requestName: "documentFindManyByTags",
                    args: {
                        tags: ["home-services"]
                    },
                    req,
                    res
                });

                if (response && response["documentFindManyByTags"]) {

                    res.wappResponse.store.dispatch(wapp.states.stateManager.actions.res({
                        type: "INS_RES",
                        name: "responses",
                        value: {"homeServices": response["documentFindManyByTags"], "documentFindManyByTags": null}
                    }));

                }

            }

            const categoryMenu = res.wappResponse.store.getState("res.responses.categoryMenu");

            if (!categoryMenu) {

                const userStatusManager = wapp.getTargetObject().postTypes.findPostType({name: "user"}).statusManager;
                const statusManager = wapp.getTargetObject().postTypes.findPostType({name: "category"}).statusManager;

                const response = await wapp.requests.send({
                    requestName: "categoryFindMany",
                    args: {
                        filter: {
                            showOnMenu: true,
                            _operators:{
                                _status: {gt: statusManager.getMinStatus() - 1},
                                _author_status: {
                                    gt: userStatusManager.getMinStatus() - 1
                                }
                            }
                        },
                        sort: "ORDER_ASC"
                    },
                    req,
                    res
                });

                if (response && response["categoryFindMany"]) {

                    res.wappResponse.store.dispatch(wapp.states.stateManager.actions.res({
                        type: "INS_RES",
                        name: "responses",
                        value: {"categoryMenu": response["categoryFindMany"], "categoryFindMany": null}
                    }));

                }

            }

            const homeFeatured = res.wappResponse.store.getState("res.responses.homeFeatured");

            if (!homeFeatured) {

                const userStatusManager = wapp.getTargetObject().postTypes.findPostType({name: "user"}).statusManager;
                const statusManager = wapp.getTargetObject().postTypes.findPostType({name: "artwork"}).statusManager;

                const response = await wapp.requests.send({
                    requestName: "artworkFindMany",
                    args: {
                        filter: {
                            _operators:{
                                _status: {gt: statusManager.getMinStatus() - 1},
                                _author_status: {
                                    gt: userStatusManager.getMinStatus() - 1
                                },
                            },
                            categories: ["home-featured"]
                        }
                    },
                    req,
                    res
                });

                if (response && response["artworkFindMany"]) {

                    res.wappResponse.store.dispatch(wapp.states.stateManager.actions.res({
                        type: "INS_RES",
                        name: "responses",
                        value: {"homeFeatured": response["artworkFindMany"], "artworkFindMany": null}
                    }));

                }

            }

        };
    }

}
