// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@media(max-width: 799px){.tNoN3{min-height:100%}.tNoN3{border-radius:0;max-width:100%;width:100%;margin:0;max-height:100%}.t99MN{padding:16px}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dialogPaper": "tNoN3",
	"dialogContent": "t99MN"
};
module.exports = ___CSS_LOADER_EXPORT___;
