import React from "react";

import ViewInArIcon from "@mui/icons-material/ViewInAr";
import DeleteIcon from "@mui/icons-material/Delete";
import ViewInArOutlined from "@mui/icons-material/ViewInArOutlined";
import AddIcon from "@mui/icons-material/Add";

import capitalize from "../../utils/capitalize";

import defaultPostTypeConfig from "../post";
import Content from "./Content";
import Edit from "./Edit";
import New from "./New";

import setContents from "./contents";
import getConstants from "./constants";

import getMenu from "./getMenu";

const postTypeConfig = {
    getStatusManager: function getStatusManager(p = {}) {
        return defaultPostTypeConfig.getStatusManager({
            ...p,
            config: {
                requiredDataForStatus: {
                    title: { type: String },
                    content: { type: String },
                },
                ...(p.config) ? p.config : {}
            },
        })
    },
    getConstants: getConstants,
    setContents: setContents,
    requestForUserPage: async function requestForUserPage(p = {}) {
        return await defaultPostTypeConfig.requestForUserPage({
            ...p,
            name:"artwork"
        })
    },
    getComponent: function (p) {

        const name = "artwork";
        const ns = (name.endsWith("y")) ? name.slice(0,-1)+"ies" : name+"s";

        const {context, appContext} = p;
        const {routes, /*userStatusManager*/} = appContext;

        const {wapp, res, req} = context;
        const route = res.wappResponse.route;
        const {requestPath, params} = route;
        const user = req.wappRequest.user;

        const isAdmin = user?._status_isFeatured;

        const pages = {
            content: Content,
            edit: Edit,
            new: New
        };

        if ((requestPath.startsWith(routes[name+"Route"])) || (requestPath.startsWith(routes[ns+"Route"]))){

            if ((requestPath.startsWith(routes[name+"Route"]))){

                if (requestPath === routes[name+"Route"] + "/new" && !isAdmin && user){
                    return defaultPostTypeConfig.getComponent({...p, name, componentProps:{pages, getPageName:()=>"notFound", layoutType: "article"}});
                }

                const requestKeys = [name+"FindById", name+"FindBySlug"];
                const slugPattern = /^[a-zA-Z0-9](-?[a-zA-Z0-9]){1,150}$/;
                const objectIdPattern = /^[0-9a-fA-F]{24}$/;

                return defaultPostTypeConfig.getComponent({
                    ...p,
                    name,
                    archiveComponentProps:{
                        type: (isAdmin) ? "table" : "card",
                        onClick: (e, post)=>{
                            const isAuthor = ((user?._id && user?._id === post?._author) || (user?._id && user?._id === post?._author?._id));
                            const href = (isAuthor || isAdmin) ? appContext.routes[name+"Route"]+"/"+post._id : appContext.routes[name+"Route"]+"/widescreen/"+post._id
                            e.preventDefault();
                            wapp.getTargetObject().history.push({
                                search:"",
                                hash:"",
                                ...wapp.getTargetObject().history.parsePath(href)
                            });
                        },
                        getMenu: (p)=>getMenu({name, ...p})
                    },
                    componentProps:{
                        pages,
                        layoutType: "article",
                        showAuthor: ({user})=>user?._status_isFeatured,
                        showCreatedDate: ({user, author})=>user?._id && user._id === author?._id,
                        getMenu: (p)=>getMenu({
                            name,
                            appPath: "/"+"/:page/:innerPage1/:innerPage2/:innerPage3"
                                .split("/:")
                                .filter((key)=>params[key])
                                .map((key)=>params[key])
                                .join("/"),
                            widescreenIsFeatured: true,
                            widescreenEnableUser: true,
                            ...p
                        }),
                        requestKeys,
                        getInitialResponse: function () {
                            const wappResponse = res.wappResponse;
                            const route = wappResponse.route;
                            const {params} = route;
                            const {_id} = params;

                            const isSlugRequest = !_id?.match(objectIdPattern) && _id?.match(slugPattern);
                            const requestName = _id?.match(objectIdPattern) ? name+"FindById" : isSlugRequest ? name+"FindBySlug" : null;
                            if (requestName) {
                                const posts = [wappResponse.store.getState("res.responses."+name+"FindById"), wappResponse.store.getState("res.responses."+name+"FindBySlug")];
                                return posts.find((p) => isSlugRequest ? p?.slug === _id : p?._id === _id);
                            }

                            return null;

                        }
                    }
                });

            }
        }
    },
    userPageFunctions: function (p) {

        const {context, /*appContext, */postContext} = p;

        const name = "artwork";
        const ns = (name.endsWith("y")) ? name.slice(0,-1)+"ies" : name+"s";
        const N = capitalize(name);
        const Ns = capitalize(ns);

        const r = defaultPostTypeConfig.userPageFunctions({
            ...p,
            name,
            nsPageProps: {
                getMenu: (p)=>getMenu({name, ...p}).filter((menu, i)=>i !== 1)
            }
        });

        const {res} = context;

        const wappResponse = res.wappResponse;
        const route = wappResponse.route;
        const {params} = route;
        const {pageType} = params;

        //const {userStatusManager} = appContext;

        function addMenuItems(props) {

            const {appContext, /*statusManager*/} = props;
            const {menus, routes, /*userStatusManager*/} = appContext;

            return [
                {
                    name: function (p) {
                        const isAuthor = ((p.user?._id && p.user?._id === p.post?._author) || (p.user?._id && p.user?._id === p.post?._author?._id));
                        return (isAuthor) ? menus["my"+Ns+"Menu"] : menus[postContext.name+Ns+"Menu"];
                    },
                    href: function (p) {
                        return (p.post?._id) ? "/"+p.post._id + routes[postContext.name+Ns+"Route"] : routes[postContext.name+Ns+"Route"];
                    },
                    role: function (p) {
                        return ((p.user && p.user._status_isFeatured) || (p.post && p.post._status_isFeatured));
                    },
                    Icon: ViewInArIcon,
                    order: 12,
                },
                {
                    name: function () {
                        return menus["deleted"+Ns+"Menu"];
                    },
                    href: function (p) {
                        return (p.post?._id) ? "/"+p.post._id + routes[postContext.name+Ns+"Route"]+"/deleted" : routes[postContext.name+Ns+"Route"]+"/deleted"
                    },
                    role: function (p) {
                        const isAdmin = p.user && p.user._status_isFeatured;
                        const isPostsPage = ((p.page === ns && !p.pageType) || (p.page === ns && !isNaN(Number(p.pageType)) && Number(pageType) >= 1 ));
                        return (isPostsPage && isAdmin);
                    },
                    Icon: DeleteIcon,
                    onlyIcon: true,
                    featured: true,
                },
            ];
        }

        function addContentMenuItems(props) {

            const {appContext, /*statusManager*/} = props;
            const {menus, routes, /*userStatusManager*/} = appContext;

            return [
                {
                    name: menus["new"+N+"MenuTheVaseAndTheFinial"],
                    href: routes[name+"Route"] + "/new/the-vase-and-the-finial",
                    role: function (p) {
                        const isAuthor = ((p.user?._id && p.user?._id === p.post?._author) || (p.user?._id && p.user?._id === p.post?._author?._id));
                        const isAdmin = p.user && p.user._status_isFeatured;
                        return !!(isAdmin && isAuthor);
                    },
                    Icon: AddIcon,
                    disableParentRoute: true,
                    order: 10,
                },
                {
                    name: menus["new"+N+"MenuHatchetOnTheTable"],
                    href: routes[name+"Route"] + "/new/hatchet-on-the-table",
                    role: function (p) {
                        const isAuthor = ((p.user?._id && p.user?._id === p.post?._author) || (p.user?._id && p.user?._id === p.post?._author?._id));
                        const isAdmin = p.user && p.user._status_isFeatured;
                        return !!(isAdmin && isAuthor);
                    },
                    Icon: AddIcon,
                    disableParentRoute: true,
                    order: 10,
                },
                {
                    name: menus["new"+N+"MenuAtonementTheVictim"],
                    href: routes[name+"Route"] + "/new/atonement-the-victim",
                    role: function (p) {
                        const isAuthor = ((p.user?._id && p.user?._id === p.post?._author) || (p.user?._id && p.user?._id === p.post?._author?._id));
                        const isAdmin = p.user && p.user._status_isFeatured;
                        return !!(isAdmin && isAuthor);
                    },
                    Icon: AddIcon,
                    disableParentRoute: true,
                    order: 10,
                },
                {
                    name: menus["new"+N+"MenuTheConstructionsApp"],
                    href: routes[name+"Route"] + "/new/the-constructions-app",
                    role: function (p) {
                        const isAuthor = ((p.user?._id && p.user?._id === p.post?._author) || (p.user?._id && p.user?._id === p.post?._author?._id));
                        const isAdmin = p.user && p.user._status_isFeatured;
                        return !!(isAdmin && isAuthor);
                    },
                    Icon: AddIcon,
                    disableParentRoute: true,
                    order: 10,
                },
                {
                    name: menus["new"+N+"MenuTheBinaryWords"],
                    href: routes[name+"Route"] + "/new/binary-words",
                    role: function (p) {
                        const isAuthor = ((p.user?._id && p.user?._id === p.post?._author) || (p.user?._id && p.user?._id === p.post?._author?._id));
                        const isAdmin = p.user && p.user._status_isFeatured;
                        return !!(isAdmin && isAuthor);
                    },
                    Icon: AddIcon,
                    disableParentRoute: true,
                    order: 10,
                },
                {
                    role: function (p) {
                        const isAuthor = ((p.user?._id && p.user?._id === p.post?._author) || (p.user?._id && p.user?._id === p.post?._author?._id));
                        const isAdmin = p.user && p.user._status_isFeatured;
                        return !!(isAdmin && isAuthor);
                    },
                    divider: true,
                    order: 12,
                },
                {
                    name: function (p) {
                        const isAuthor = ((p?.user?._id && p?.user?._id === p?.post?._author) || (p?.user?._id && p?.user?._id === p?.post?._author?._id));
                        return (isAuthor) ? menus["my"+Ns+"Menu"] : menus[postContext.name+Ns+"Menu"];
                    },
                    href: function (p) {
                        return (p?.post?._id) ? "/"+p.post._id + routes[postContext.name+Ns+"Route"] : routes[postContext.name+Ns+"Route"];
                    },
                    role: function (p) {
                        const isAuthor = ((p.user?._id && p.user?._id === p.post?._author) || (p.user?._id && p.user?._id === p.post?._author?._id));
                        return (!isAuthor && p.post && p.post._status_isFeatured);
                    },
                    Icon: ViewInArIcon,
                    order: 12,
                },
            ]
        }

        function getPageName({user, post, page, /*statusManager*/}) {

            const isAdmin = user && user._status_isFeatured;
            const isAuthor = ((user?._id && user._id === post?._author) || (user?._id && user._id === post?._author?._id));
            const isAuthorOrAdmin = (isAdmin || isAuthor);
            const authorIsAdmin = post && post._status_isFeatured;

            return ((page === ns && !pageType) || (page === ns && !isNaN(Number(pageType)) && Number(pageType) >= 1 ) || (page === ns && pageType === "deleted" && isAuthorOrAdmin)) ? (isAdmin || authorIsAdmin) ? page : "notFound" : null;

        }

        return {
            ...r,
            addMenuItems,
            addContentMenuItems,
            getPageName
        }
    },
    adminMenu: function (p) {
        const name = "artwork";
        const defaultAdminMenu = defaultPostTypeConfig.adminMenu({...p, name});
        defaultAdminMenu.Icon = ViewInArIcon;
        defaultAdminMenu.order = 1;
        defaultAdminMenu.items[0].Icon = ViewInArOutlined;
        return defaultAdminMenu;
    }
};

export default postTypeConfig;
