import React, {useContext, useMemo, useState, useEffect} from "react";

import {WappContext, withWapp} from "wapplr-react/dist/common/Wapp";
import getUtils from "wapplr-react/dist/common/Wapp/getUtils";

import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";

import {withMaterialStyles} from "../Template/withMaterial";

import Search from "./Search";
import AppContext from "../App/context";

import style from "./style.css";
import materialStyle from "./materialStyle";


function ListTools(props) {

    const context = useContext(WappContext);
    const appContext = useContext(AppContext);
    const {/*materialStyle, */
        data = {},
        disableSearch,
        disableSort,
        searchOnChange,
        name = "post",
        subscribe,
        SearchFormElement
    } = props;

    const ns = (name.endsWith("y")) ? name.slice(0,-1)+"ies" : name+"s";

    const {wapp, req} = context;
    const utils = getUtils(context);
    const query = req.wappRequest.query;

    wapp.styles.use(style);

    const getBaseUrlForSort = useMemo(()=>(url)=>{
        let urlWithoutSearch = url.split("?")[0];
        if (urlWithoutSearch.slice(-1) === "/") {
            urlWithoutSearch = urlWithoutSearch.slice(0,-1);
        }
        const sortPart = urlWithoutSearch.match(/\/sort\/\w+/);
        const pagePart = urlWithoutSearch.match(/\/page\/\d+/);
        return {
            pathname: sortPart || pagePart ? urlWithoutSearch.split(sortPart).join("").split(pagePart).join("") : urlWithoutSearch,
            search: url.split("?")[1]
        };
    }, []);

    const getSortFromUrl = useMemo(()=>(url)=>{
        let urlWithoutSearch = url.split("?")[0];
        if (urlWithoutSearch.slice(-1) === "/") {
            urlWithoutSearch = urlWithoutSearch.slice(0,-1);
        }
        const sortPart = urlWithoutSearch.match(/\/sort\/\w+/);
        return (sortPart && sortPart[0].split("/")[2]) || "";
    }, []);

    const getBaseUrlForPerPage = useMemo(()=>(url)=>{
        let urlWithoutSearch = url.split("?")[0];
        if (urlWithoutSearch.slice(-1) === "/") {
            urlWithoutSearch = urlWithoutSearch.slice(0,-1);
        }
        const perPagePart = urlWithoutSearch.match(/\/limit\/\w+/);
        const pagePart = urlWithoutSearch.match(/\/page\/\d+/);
        return {
            pathname: perPagePart || pagePart ? urlWithoutSearch.split(perPagePart).join("").split(pagePart).join("") : urlWithoutSearch,
            search: url.split("?")[1]
        };
    }, []);

    const getPerPageFromUrl = useMemo(()=>(url)=>{
        let urlWithoutSearch = url.split("?")[0];
        if (urlWithoutSearch.slice(-1) === "/") {
            urlWithoutSearch = urlWithoutSearch.slice(0,-1);
        }
        const sortPart = urlWithoutSearch.match(/\/limit\/\w+/);
        return (sortPart && sortPart[0].split("/")[2]) || "";
    }, []);

    const listData = utils.getGlobalState("res.graphql.query."+name+"FindMany.listData") || [];
    let listDataSort = listData.sort || [];

    const searchText = typeof props.searchText == "string" ? props.searchText : typeof query.search == "string" ? query.search : "";

    let defaultSort = (listDataSort[0]?.key) || "";

    if (searchText){
        listDataSort = [{key: "TEXTSCORE", listData: {title: {label: appContext.labels[ns+"SortRelevance"]}}}, ...listDataSort];
        if (!defaultSort){
            defaultSort = (listDataSort[0]?.key) || "";
        }
    }

    const [url, setUrl] = useState(utils.getRequestUrl());

    const sortFromUrl = getSortFromUrl(url);
    const [sort, setSort] = useState(props.sort || sortFromUrl || defaultSort);

    const perPageFromUrl = getPerPageFromUrl(url);
    const listDataPerPage = listData.perPage;
    const defaultPerPage = listDataPerPage.default || 20;
    const limitPerPage = listDataPerPage.limit || 100;
    const stepCaPerPage = Math.floor(limitPerPage/defaultPerPage) > 1 ? Math.floor(limitPerPage/defaultPerPage) : 1;
    const minPerPage = (limitPerPage/stepCaPerPage) >= 1 && (limitPerPage/stepCaPerPage) <= defaultPerPage && (limitPerPage/stepCaPerPage) <= limitPerPage ? limitPerPage/stepCaPerPage : 1;

    function getNumberOrNull(n){
        return (n && !isNaN(Number(n))) ? Number(n) : null;
    }
    const [perPage, setPerPage] = useState(getNumberOrNull(props.perPage) || getNumberOrNull(data.perPage) || getNumberOrNull(perPageFromUrl) || getNumberOrNull(defaultPerPage));

    const perPageOptionsLength = Math.floor(limitPerPage/minPerPage) > 1 ? Math.floor(limitPerPage/minPerPage) : 1;
    const perPageOptionsStep = Math.floor((limitPerPage-minPerPage)/perPageOptionsLength);
    const perPageOptions = [
        perPage,
        minPerPage,
        defaultPerPage,
        limitPerPage,
        ...[...Array(perPageOptionsLength).keys()].map((_, i)=>minPerPage+(perPageOptionsStep*(i+1)))
    ]
        .map((n)=>n > 10 ? Math.round(n/10)*10 : Math.round(n))
        .filter((n)=> n >= minPerPage && n <= limitPerPage)
        .filter((value, index, self)=>self.indexOf(value) === index)
        .filter((value)=>value > 1)
        .sort((a, b)=>{return a > b ? 1 : -1});

    const {currentPage = 1, itemCount = 0} = data;
    const startItem = ((currentPage-1) * perPage) + 1;
    const endItem = Math.min(currentPage * perPage, itemCount);
    const allItem = itemCount;

    useEffect(function (){

        function onLocationChange(newUrl){
            if (url !== newUrl){
                setUrl(newUrl);
            }
        }

        const unsub = subscribe.locationChange(onLocationChange);
        return function useUnsubscribe(){
            unsub();
        }
    }, [subscribe, url]);

    const sortOnChange = useMemo(()=>async (e)=>{
        e.preventDefault();
        const newSort = e.target.value || "";

        if (sort !== newSort) {

            const {pathname, search} = getBaseUrlForSort(url);
            const newUrl = search ? pathname + "/sort/" + newSort + "?" + search : pathname + "/sort/" + newSort;

            if (props.sortOnChange){
                await setSort(newSort);
                props.sortOnChange({sort: newSort, pathname, search});
            } else {
                wapp.client.history.push({
                    search:"",
                    hash:"",
                    ...wapp.client.history.parsePath(newUrl)
                });
            }
        }
    }, [getBaseUrlForSort, props, sort, url, wapp.client?.history]);

    const perPageOnChange = useMemo(()=>async (e)=>{
        e.preventDefault();
        const newPerPage = e.target.value || "";

        if (perPage !== newPerPage) {

            const {pathname, search} = getBaseUrlForPerPage(url);
            const newUrl = search ? pathname + "/limit/" + newPerPage + "?" + search : pathname + "/limit/" + newPerPage;

            if (props.perPageOnChange){
                await setPerPage(newPerPage);
                props.perPageOnChange({perPage: newPerPage, pathname, search});
            } else {
                wapp.client.history.push({
                    search:"",
                    hash:"",
                    ...wapp.client.history.parsePath(newUrl)
                });
            }
        }
    }, [perPage, getBaseUrlForPerPage, url, props, wapp.client?.history]);

    return (
        <div className={style.listTools}>
            {(!disableSearch) ? <Search onChange={searchOnChange} searchText={props.searchText} FormElement={SearchFormElement} /> : null}
            {(!disableSort && listDataSort.length) || (allItem > 1) ?
                <div className={style.right}>
                    {(!disableSort && listDataSort.length) ?
                        <FormControl
                            className={style.selectFormControl}
                            fullWidth
                        >
                            <Select
                                value={sort}
                                onChange={sortOnChange}
                                className={style.select}
                                native={true}
                                color={"secondary"}
                            >
                                {(listDataSort.map((sortData, i)=>{
                                    const label = Object.keys(sortData.listData).filter((key)=>sortData.listData[key].label).map((key)=>sortData.listData[key].label).join(", ") || sortData.key;
                                    return (
                                        <option
                                            key={i}
                                            value={sortData.key}
                                            disabled={sortData.key === sort}
                                        >
                                            {label}
                                        </option>
                                    )
                                }))}
                            </Select>
                        </FormControl>
                        : null
                    }
                    {(allItem > 1) ?
                        <FormControl
                            className={style.selectFormControl}
                            fullWidth
                        >
                            <Select
                                value={"default"}
                                onChange={perPageOnChange}
                                className={style.select}
                                native={true}
                                color={"secondary"}
                            >
                                <option
                                    key={"default"}
                                    disabled={true}
                                    value={"default"}
                                >
                                    {`${startItem} - ${endItem} of ${allItem}`}
                                </option>
                                {(perPageOptions.map((n, i)=>{
                                    return (
                                        <option
                                            key={i}
                                            value={n}
                                            disabled={n === Number(perPage)}>
                                            {n.toString()}
                                        </option>
                                    )
                                }))}
                            </Select>
                        </FormControl>
                        :
                        null
                    }
                </div>
                : null
            }
        </div>
    )
}

const WappComponent = withWapp(ListTools);

export default withMaterialStyles(materialStyle, WappComponent);
