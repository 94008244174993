import React from "react";

import App from "../../components/App";
import capitalize from "../../utils/capitalize";
import {runPostTypesConfig} from "../index";

import Widescreen from "./Widescreen";
import {getTitleFunction, getDescriptionFunction, getListContent} from "../post/contents";

import collectRouteParams from "../../utils/collectRouteParams";

import getApp from "./getApp";

export default function setContents(p = {}) {

    const {wapp, messages, routes, titles, menus, labels, name = "artwork", getTitle = function ({title}) {return title;}} = p;
    const n = name;
    const N = capitalize(n);
    const ns = n+"s";

    const urlParams = [
        "/page/:pagination",
        "/sort/:sort",
        "/limit/:limit",
        "/categories/:categories"
    ];

    let reqUserForPost = null;

    const slugPattern = /^[a-zA-Z0-9](-?[a-zA-Z0-9]){1,150}$/;
    const objectIdPattern = /^[0-9a-fA-F]{24}$/;

    async function request ({wapp, req, res}) {
        const wappResponse = res.wappResponse;
        const route = wappResponse.route;
        const {params} = route;
        const {_id, page} = params;

        const isSlugRequest = !_id?.match(objectIdPattern) && _id?.match(slugPattern);
        const requestName = _id?.match(objectIdPattern) ? name+"FindById" : isSlugRequest ? name+"FindBySlug" : null;

        const posts = [wappResponse.store.getState("res.responses."+name+"FindById"), wappResponse.store.getState("res.responses."+name+"FindBySlug")];
        const post = posts.find((p)=>isSlugRequest ? p?.slug === _id : p?._id === _id);
        const args = isSlugRequest ? {slug: _id} : {_id};

        const statusManager = wapp.getTargetObject().postTypes.findPostType({name: n}).statusManager;

        await runPostTypesConfig({action: "requestFor" + N + "Page", p: {wapp, req, res, statusManager}});

        if (requestName && _id || route.requestPath.startsWith(routes[n+"Route"] + "/new") && params.appSlug) {

            let r;

            if (!requestName ||
                !((reqUserForPost?._id !== req.wappRequest.user?._id) ||
                (_id && !post) ||
                (!isSlugRequest && post?._id !== _id) ||
                (isSlugRequest && post?.slug !== _id))
            ){
                r = {
                    [requestName]: post
                }
            } else {
                reqUserForPost = {_id: req.wappRequest.user?._id};
                r = await wapp.requests.send({requestName, args, req, res});
            }

            const appSlug = route.requestPath.startsWith(routes[n+"Route"] + "/new") && params.appSlug ||
                !isSlugRequest && r && r[n + "FindById"] && r[n + "FindById"].appSlug ||
                isSlugRequest && r && r[n + "FindBySlug"] && r[n + "FindBySlug"].appSlug ||
                post?.appSlug || "default-app";

            const responsePostId = !isSlugRequest && r && r[n + "FindById"] && r[n + "FindById"]._id ||
                isSlugRequest && r && r[n + "FindBySlug"] && r[n + "FindBySlug"]._id

            const {createChildWapp} = await getApp(appSlug);

            let url = "/"+"/:page/:innerPage1/:innerPage2/:innerPage3".split("/:").filter((key)=>params[key]).map((key)=>params[key]).join("/");
            if (page === "edit" || route.requestPath.startsWith(routes[n+"Route"] + "/new") && params.appSlug) {
                url = "/editor"
            }
            const queryString = Object.keys(req.wappRequest.query).map((key) => ""+key+"="+encodeURIComponent(req.wappRequest.query[key])).join("&");

            const postId = post?._id || responsePostId || _id;

            const parentRoute = (page === "edit") ?
                routes[n + "Route"] + _id + "/edit" :
                (route.requestPath.startsWith(routes[n + "Route"] + "/new")) ?
                    routes[n + "Route"] + "/new" :
                    (route.path.match("widescreen")) ?
                        routes[n + "Route"] + "/widescreen/" + postId :
                        routes[n + "Route"] + "/" + postId;

            const artworkChild = await createChildWapp({
                parentWapp: wapp,
                parentReq: req,
                parentRoute,
                config: {globals: {WAPP: (postId) ? wapp.globals.WAPP + "_"+appSlug+"_" + postId : wapp.globals.WAPP + "_"+appSlug+"_" + "new"}},
                req: {
                    path: url,
                    url: (queryString) ? url + "?" + queryString : url
                }
            });

            res.wappResponse[n + 'App'] = {...artworkChild};

            return r;

        }

    }

    const titleFunction = getTitleFunction({name, titles, routes, getTitle});

    wapp.contents.add({
        [n]: {
            title: titleFunction,
            description: getDescriptionFunction({name, getTitle: titleFunction}),
            render: App,
            renderType: "react",
            request: request
        },
    });

    wapp.contents.add({
        [ns]: getListContent({name, titles, routes, getTitle, urlParams})
    });

    wapp.contents.add({
        [n+"_widescreen"]: {
            title: titleFunction,
            description: getDescriptionFunction({name, getTitle: titleFunction}),
            render: (props)=> <Widescreen {...props} name={n} appContext={{messages, routes, menus, titles, labels}} />,
            renderType: "react",
            request: request
        }
    });

    wapp.contents.add({
        [n+"_api"]: {
            render: function (req, res) {

                res.setHeader("Access-Control-Allow-Origin", "*");
                res.setHeader("Access-Control-Allow-Headers", "*");
                res.setHeader("Access-Control-Allow-Methods", "POST, OPTIONS");
                if (req.method === "OPTIONS"){
                    return {};
                }

                const wappResponse = res.wappResponse;
                const state = wappResponse.store.getState();
                const post = state.res.responses && state.res.responses[n+"FindById"] || {};
                return {...post}
            },
            renderType: "post",
            request: request
        },
    });

    const paths = collectRouteParams({
        startRoutes: [
            {path: routes[ns+"Route"], contentName: ns},
            {path: routes[ns+"Route"]+"/:page", contentName: ns},
        ],
        params: urlParams
    });

    wapp.router.add([
        {path: routes[n+"Route"], contentName: n},
        {path: routes[n+"Route"]+"/new", contentName: n},
        {path: routes[n+"Route"]+"/new/:appSlug/", contentName: n},
        {path: routes[n+"Route"]+"/widescreen/:_id", contentName: n+"_widescreen"},
        {path: routes[n+"Route"]+"/widescreen/:_id/:page", contentName: n+"_widescreen"},
        {path: routes[n+"Route"]+"/widescreen/:_id/:page/:innerPage1/", contentName: n+"_widescreen"},
        {path: routes[n+"Route"]+"/widescreen/:_id/:page/:innerPage1/:innerPage2/:innerPage3", contentName: n+"_widescreen"},
        {path: routes[n+"Route"]+"/:_id", contentName: n},
        {path: routes[n+"Route"]+"/:_id/:page", contentName: n},
        {path: routes[n+"Route"]+"/:_id/:page/:innerPage1", contentName: n},
        {path: routes[n+"Route"]+"/:_id/:page/:innerPage1/:innerPage2/:innerPage3", contentName: n},
        {path: (wapp.globals.DEV) ? "/artwork-api/:_id" : "/c" + wapp.globals.WAPP+"/:_id", contentName: n+"_api"},

        ...paths

    ]);

    return {
        name: n
    };

}
