export default function getRandomFunction(hash) {
    const alphabet = "123456789abcdefghijkmnopqrstuvwxyzABCDEFGHJKLMNPQRSTUVWXYZ"

    if (!hash) {
        hash = "oo" + Array(49).fill(0).map(_=>alphabet[(Math.random()*alphabet.length)|0]).join('')
    }

    const b58dec = str=>[...str].reduce((p,c)=>p*alphabet.length+alphabet.indexOf(c)|0, 0)
    const hashTrunc = hash.startsWith("oo") ? hash.slice(2) : hash.startsWith("tz1") ? hash.slice(3) : hash.slice(2);
    const regex = new RegExp(".{" + ((hashTrunc.length / 4) | 0) + "}", "g")
    const hashes = hashTrunc.match(regex).map((h) => b58dec(h))

    let shownConsole = false;
    const sfc32 = (a, b, c, d) => {
        return () => {
            if (!shownConsole) {
                shownConsole = true;
                console.log("[APP]", "Hash for random:", hash);
            }
            a |= 0
            b |= 0
            c |= 0
            d |= 0
            let t = (((a + b) | 0) + d) | 0
            d = (d + 1) | 0
            a = b ^ (b >>> 9)
            b = (c + (c << 3)) | 0
            c = (c << 21) | (c >>> 11)
            c = (c + t) | 0
            return (t >>> 0) / 4294967296
        }
    }

    return sfc32(...hashes)
}
