// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".hemON{border-style:solid;border-width:1px;border-color:var(--border-color);border-radius:5px;overflow:hidden}.BuhhM{height:100%}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "hemON",
	"heightByParent": "BuhhM"
};
module.exports = ___CSS_LOADER_EXPORT___;
