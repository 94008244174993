import React, {useContext, useState, useMemo} from "react";

import {WappContext, withWapp} from "wapplr-react/dist/common/Wapp";
import Close from "@mui/icons-material/Close";

import {withMaterialStyles} from "../Template/withMaterial";

import style from "./style.css";
import materialStyle from "./materialStyle";
import Dialog from "../Dialog";
import Swipeable from "../Swipeable";
import clsx from "clsx";

const Image = React.memo((props)=> <img alt={props.alt} {...props}/>, ()=>true);

export function Gallery(props) {

    const context = useContext(WappContext);
    const {wapp} = context;

    const [gallery] = useState(props.gallery || []);

    wapp.styles.use(style);

    const dialog = useMemo(()=>{return {}}, []);
    const dialogEffect = useMemo(() => ({actions}) => {dialog.actions = actions;}, [dialog]);

    const onClick = useMemo(()=>(e, index)=>{

        dialog.actions.open({
            dialogTitle: "",
            dialogContent: <div className={style.swipeableContainer}>
                <div
                    className={style.close}
                    onClick={()=>dialog.actions.close()}
                >
                    <Close />
                </div>
                <Swipeable
                    gallery={gallery}
                    fixHeight={true}
                    initialIndex={index}
                />
            </div>,
            dialogContentClassName: style.dialogContentClassName,
            cancelText: null,
            submitText: null,
        })

    }, [dialog.actions, gallery]);

    const startJustOne = typeof props.startJustOne == "number" && props.startJustOne >= 0 ?
        props.startJustOne :
        props.startJustOne === true ? 0 : -1;

    return (
        <div className={clsx(
            style.gallery,
            {[style.startJustOne]: startJustOne >= 0}
        )}>
            {(gallery?.length) ?
                <div className={style.grid}>
                    {
                        gallery.filter((post, i)=>startJustOne >= 0 ? i === startJustOne : true).map((post, i)=> {
                            const thumb = post.thumb;
                            return (
                                <div className={style.child} key={i}>
                                    <div
                                        className={style.item}
                                        onClick={(e)=>onClick(e, startJustOne >= 0 ? startJustOne : i)}
                                    >
                                        <Image
                                            src={thumb}
                                            alt={post.title}
                                        />
                                    </div>
                                </div>
                            )
                        })
                    }
                    <Dialog
                        fullWidth
                        maxWidth={"xl"}
                        effect={dialogEffect}
                        className={style.dialog}
                        paperClassName={style.dialogPaper}
                    />
                </div>
                : null}
        </div>
    )
}

const WappComponent = withWapp(Gallery);

export default withMaterialStyles(materialStyle, WappComponent);
