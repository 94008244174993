// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AQiHs{position:relative}.AQiHs.IgYuZ{padding-bottom:56%;background-color:var(--divider-color)}.AQiHs a{display:block}.AQiHs .\\+K-af{width:100%;height:auto;vertical-align:top;-webkit-transition:all 1000ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;-o-transition:all 1000ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;transition:all 1000ms cubic-bezier(0.4, 0, 0.2, 1) 0ms}.AQiHs.IgYuZ .\\+K-af{display:none}.AQiHs>*:nth-last-child(2){border-bottom-right-radius:8px;border-bottom-left-radius:8px;overflow:hidden}.AQiHs .Q1MPu{display:none}.AQiHs .Q1MPu svg{width:56px;height:56px}.AQiHs.IgYuZ .Q1MPu{display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;-ms-flex-pack:center;justify-content:center;height:100%;width:100%;position:absolute;top:0;left:0}.AQiHs .eXJbm{min-height:128px;padding:32px}.AQiHs .eXJbm._00b2V{padding:0}.AQiHs.ZpMsH>div{border-bottom-right-radius:8px;border-bottom-left-radius:8px;overflow:hidden}@media(max-width: 599px){.AQiHs .eXJbm{padding:16px}.AQiHs .eXJbm._00b2V{padding:0}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"post": "AQiHs",
	"error": "IgYuZ",
	"image": "+K-af",
	"noImageIconContainer": "Q1MPu",
	"content": "eXJbm",
	"noPadding": "_00b2V",
	"thereIsNotContent": "ZpMsH"
};
module.exports = ___CSS_LOADER_EXPORT___;
