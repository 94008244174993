import React, {useContext, useState, useEffect} from "react";
import getUtils from "wapplr-react/dist/common/Wapp/getUtils";
import {WappContext, withWapp} from "wapplr-react/dist/common/Wapp";

import {withMaterialStyles} from "../Template/withMaterial";

import style from "./style.css";
import materialStyle from "./materialStyle";
import clsx from "clsx";
import Button from "@mui/material/Button";

import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";

function Pagination(props) {

    const context = useContext(WappContext);
    const {materialStyle, subscribe, data = {}} = props;
    const {wapp} = context;
    const {hasNextPage, hasPreviousPage, currentPage = 1} = data;
    const utils = getUtils(context);

    wapp.styles.use(style);

    const [url, setUrl] = useState(utils.getRequestUrl());

    function getBaseUrl(url) {
        let urlWithoutSearch = url.split("?")[0];
        if (urlWithoutSearch.slice(-1) === "/") {
            urlWithoutSearch = urlWithoutSearch.slice(0,-1);
        }
        const pagePart = urlWithoutSearch.match(/\/page\/\d+/);
        return {
            pathname: pagePart ? urlWithoutSearch.split(pagePart).join("") : urlWithoutSearch,
            search: url.split("?")[1]
        };
    }

    async function onClick(e, {action}) {

        e.preventDefault();

        const {pathname, search} = getBaseUrl(url);

        const page = (action === "prev") ? currentPage - 1 : (action === "next") ? currentPage + 1 : currentPage;
        const newUrl = search ? pathname + "/page/" + page + "?" + search : pathname + "/page/" + page;

        if (props.onClick){
            await props.onClick(e, {action, newUrl, pathname, search, page})
        } else {
            wapp.client.history.push({
                search:"",
                hash:"",
                ...wapp.client.history.parsePath(newUrl)
            });
        }
    }

    useEffect(function (){
        function onLocationChange(newUrl){
            if (url !== newUrl){
                setUrl(newUrl);
            }
        }
        const unsub = subscribe.locationChange(onLocationChange);
        return function useUnsubscribe(){
            unsub();
        }
    }, [subscribe, url]);

    return (
        <div className={clsx(materialStyle.root, style.pagination)}>
            {(hasPreviousPage || hasNextPage) ?
                <div className={
                    clsx(style.prevNextContainer,
                        {
                            [style.justPrev] : (!hasNextPage),
                            [style.justNext] : (!hasPreviousPage)
                        }
                    )
                }>
                    {(hasPreviousPage) ?
                        <div>
                            <Button
                                variant={"text"}
                                onClick={(e)=>onClick(e, {action: "prev"})}
                                startIcon={<NavigateBeforeIcon />}
                            >
                                {currentPage-1}
                            </Button>
                        </div>
                        : null
                    }
                    {(hasNextPage) ?
                        <div>
                            <Button
                                variant={"text"}
                                onClick={(e)=>onClick(e, {action: "next"})}
                                endIcon={<NavigateNextIcon />}
                            >
                                {currentPage+1}
                            </Button>
                        </div>
                        : null
                    }
                </div>
                : null
            }
        </div>
    )
}

const WappComponent = withWapp(Pagination);

export default withMaterialStyles(materialStyle, WappComponent);
