import defaultPostTypeConfig from "../post";
import capitalize from "../../utils/capitalize";

export default function getConstants(p = {}) {

    const name = "artwork";
    const N = capitalize(name);
    const Ns = N+"s";

    const {
        labels,
        menus,
        messages,
        routes,
        titles
    } = defaultPostTypeConfig.getConstants({
        ...p,
        name
    });

    return {
        labels: {
            ...labels,
            ["widescreenPage"+N+"Label"]: "Widescreen",
            ["backFromWide"+N+"EntryLabel"]: "Back",
            ["serverRender"+N+"Label"]: "Rendering",
            ["serverRender"+N+"LabelSecondary"]: "All featured view and the animation",
        },
        menus: {
            ...menus,
            ["new"+N+"Menu"]: "Create an artwork",
            ["new"+N+"MenuTheVaseAndTheFinial"]: "Create [the-vase-and-the-finial]",
            ["new"+N+"MenuHatchetOnTheTable"]: "Create [hatchet-on-the-table]",
            ["new"+N+"MenuAtonementTheVictim"]: "Create [atonement-the-victim]",
            ["new"+N+"MenuTheConstructionsApp"]: "Create [the-constructions-app]",
            ["new"+N+"MenuTheBinaryWords"]: "Create [binary-words]",
            ["more"+Ns+"Menu"]: "More artworks",
        },
        messages: {
            ...messages,
        },
        routes: {
            ...routes,
        },
        titles: {
            ...titles,
        }
    }
}
