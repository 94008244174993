// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".RuCCe .RuCCe .RuCCe{width:100%;margin-bottom:24px;border-style:solid;border-width:1px;border-color:var(--border-color);padding:24px 16px 0;border-radius:4px}.RuCCe>div{width:100%;margin-bottom:16px}.RuCCe .OtYgG{margin-top:-24px;font-size:12px;font-size:.75rem;padding:16px 8px 16px 0;margin-bottom:8px}.HngLD{font-weight:400;font-size:12px;font-size:.75rem;line-height:1.66;letter-spacing:.03333em;text-align:left;margin:3px 14px 0}.oWC62{color:var(--error-color)}.VqWiM>div{width:100%}.VqWiM label{padding:6px 20px 6px 6px;border-style:solid;border-width:1px;border-radius:4px;border-color:var(--border-color);margin:0;width:100%}.K7BnW{position:relative}.ikOKZ{position:relative;padding:8px 16px 16px}.K7BnW .oMH3i{color:rgba(0,0,0,.6);font-family:\"Roboto\",\"Helvetica\",\"Arial\",sans-serif;font-weight:400;font-size:16px;font-size:1rem;line-height:1.4375em;letter-spacing:.00938em;padding:0;display:block;-webkit-transform-origin:top left;-ms-transform-origin:top left;transform-origin:top left;white-space:nowrap;overflow:hidden;-o-text-overflow:ellipsis;text-overflow:ellipsis;max-width:calc(133% - 24px);position:absolute;left:0;top:0;z-index:1;pointer-events:none;-webkit-transform:translate(14px, -9px) scale(0.75);-ms-transform:translate(14px, -9px) scale(0.75);transform:translate(14px, -9px) scale(0.75)}.K7BnW.oWC62 .oMH3i{color:var(--error-color)}.K7BnW .Ktm5r{border-radius:4px;position:relative}.K7BnW .CD3f8{text-align:left;position:absolute;bottom:0;right:0;top:-5px;left:0;margin:0;padding:0 8px;pointer-events:none;border-style:solid;border-width:1px;overflow:hidden;min-width:0;border-color:var(--border-color);border-radius:4px}.K7BnW.oWC62 .CD3f8{border-color:var(--error-color)}.K7BnW ._9d0Zk{display:block;width:auto;padding:0;height:11px;font-size:.75em;visibility:hidden;max-width:100%}.K7BnW ._9d0Zk>span{padding-left:6px;padding-right:10px;display:inline-block}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "RuCCe",
	"containerLabel": "OtYgG",
	"helperText": "HngLD",
	"error": "oWC62",
	"checkbox": "VqWiM",
	"posts": "K7BnW",
	"postsFabContainer": "ikOKZ",
	"label": "oMH3i",
	"postsList": "Ktm5r",
	"fieldset": "CD3f8",
	"legend": "_9d0Zk"
};
module.exports = ___CSS_LOADER_EXPORT___;
