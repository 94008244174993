import DescriptionIcon from "@mui/icons-material/Description";
import DeleteIcon from "@mui/icons-material/Delete";
import DescriptionOutlined from "@mui/icons-material/DescriptionOutlined";

import defaultPostTypeConfig from "../post";
import capitalize from "../../utils/capitalize";
import Content from "./Content";
import getConstants from "./constants";
import {runPostTypesConfig} from "../index";

const postTypeConfig = {
    getStatusManager: function getStatusManager(p = {}) {
        return defaultPostTypeConfig.getStatusManager({
            ...p,
            config: {
                requiredDataForStatus: {
                    title: { type: String },
                    content: { type: String },
                },
                ...(p.config) ? p.config : {}
            },
        })
    },
    getConstants: getConstants,
    setContents: function setContents(p = {}) {
        const {wapp} = p;
        const name = "document";
        const n = name;
        const N = capitalize(n);

        const r = defaultPostTypeConfig.setContents({
            ...p,
            name,
            urlParams: [
                "/page/:pagination",
                "/sort/:sort",
                "/limit/:limit",
                "/tags/:tags"
            ]
        });

        let reqUserForPost = null;

        const slugPattern = /^[a-z0-9](-?[a-z0-9]){1,150}$/;
        const objectIdPattern = /^[0-9a-fA-F]{24}$/;

        wapp.contents.add({
            [name]: {
                ...wapp.contents.get(name),
                request: async function ({wapp, req, res}) {

                    const wappResponse = res.wappResponse;
                    const route = wappResponse.route;
                    const {params} = route;
                    const {_id} = params;
                    const isSlugRequest = !_id?.match(objectIdPattern) && _id?.match(slugPattern);
                    const requestName = _id?.match(objectIdPattern) ? name+"FindById" : isSlugRequest ? name+"FindBySlug" : null;

                    const statusManager = wapp.getTargetObject().postTypes.findPostType({name: n}).statusManager;
                    await runPostTypesConfig({action: "requestFor"+N+"Page", p:{wapp, req, res, statusManager}});

                    if (requestName){

                        const posts = [wappResponse.store.getState("res.responses."+name+"FindById"), wappResponse.store.getState("res.responses."+name+"FindBySlug")];
                        const post = posts.find((p)=>isSlugRequest ? p?.slug === _id : p?._id === _id);

                        const args = isSlugRequest ? {slug: _id} : {_id};

                        if (
                            (reqUserForPost?._id !== req.wappRequest.user?._id) ||
                            (_id && !post) ||

                            (!isSlugRequest && post?._id !== _id) ||
                            (isSlugRequest && post?.slug !== _id)
                        ) {
                            reqUserForPost = {_id: req.wappRequest.user?._id};
                            return await wapp.requests.send({requestName, args, req, res});

                        } else {
                            return {
                                [requestName]: post
                            }
                        }

                    }
                }
            }
        });
        return r;
    },
    requestForUserPage: async function requestForUserPage(p = {}) {
        return await defaultPostTypeConfig.requestForUserPage({
            ...p,
            name:"document"
        })
    },
    getComponent: function (p) {

        const name = "document";
        const ns = (name.endsWith("y")) ? name.slice(0,-1)+"ies" : name+"s";

        const {context, appContext} = p;
        const {routes, /*userStatusManager*/} = appContext;

        const {res, req} = context;
        const route = res.wappResponse.route;
        const requestPath = route.requestPath;
        const user = req.wappRequest.user;

        const isAdmin = user && user._status_isFeatured;

        const pages = {
            content: Content
        };

        if ((requestPath.startsWith(routes[name+"Route"])) || (requestPath.startsWith(routes[ns+"Route"]))){
            if (requestPath === routes[name+"Route"] + "/new" && !isAdmin && user){
                return defaultPostTypeConfig.getComponent({...p, name, componentProps: {pages, getPageName:()=>"notFound", layoutType: "article"}});
            }

            const requestKeys = [name+"FindById", name+"FindBySlug"];
            const slugPattern = /^[a-z0-9](-?[a-z0-9]){1,150}$/;
            const objectIdPattern = /^[0-9a-fA-F]{24}$/;

            return defaultPostTypeConfig.getComponent({
                ...p,
                name,
                archiveComponentProps:{
                    type: (isAdmin) ? "list" : "card"
                },
                componentProps:{
                    pages,
                    layoutType: "article",
                    showAuthor: ({user})=>user?._status_isFeatured,
                    showCreatedDate: ({user, author})=>user?._id && user._id === author?._id,
                    requestKeys,
                    getInitialResponse: function () {
                        const wappResponse = res.wappResponse;
                        const route = wappResponse.route;
                        const {params} = route;
                        const {_id} = params;

                        const isSlugRequest = !_id?.match(objectIdPattern) && _id?.match(slugPattern);
                        const requestName = _id?.match(objectIdPattern) ? name+"FindById" : isSlugRequest ? name+"FindBySlug" : null;
                        if (requestName) {
                            const posts = [wappResponse.store.getState("res.responses."+name+"FindById"), wappResponse.store.getState("res.responses."+name+"FindBySlug")];
                            return posts.find((p) => isSlugRequest ? p?.slug === _id : p?._id === _id);
                        }

                        return null;

                    }
                }
            });
        }
    },
    userPageFunctions: function (p) {

        const {context, /*appContext, */postContext} = p;

        const n = "document";
        const ns = (n.endsWith("y")) ? n.slice(0,-1)+"ies" : n+"s";
        const N = capitalize(n);
        const Ns = capitalize(ns);

        const r = defaultPostTypeConfig.userPageFunctions({...p, name:n});

        const {res} = context;

        const wappResponse = res.wappResponse;
        const route = wappResponse.route;
        const {params} = route;
        const {pageType} = params;

        //const {userStatusManager} = appContext;

        function addMenuItems(props) {

            const {appContext, /*statusManager*/} = props;
            const {menus, routes, /*userStatusManager*/} = appContext;

            return [
                {
                    name: function (p) {
                        const isAuthor = ((p.user?._id && p.user?._id === p.post?._author) || (p.user?._id && p.user?._id === p.post?._author?._id));
                        return (isAuthor) ? menus["my"+Ns+"Menu"] : menus[postContext.name+Ns+"Menu"];
                    },
                    href: function (p) {
                        return (p.post?._id) ? "/"+p.post._id + routes[postContext.name+Ns+"Route"] : routes[postContext.name+Ns+"Route"];
                    },
                    role: function (p) {
                        return ((p.user && p.user._status_isFeatured) || (p.post && p.post._status_isFeatured));
                    },
                    Icon: DescriptionIcon,
                    order: 31,
                },
                {
                    name: function () {
                        return menus["deleted"+Ns+"Menu"];
                    },
                    href: function (p) {
                        return (p.post?._id) ? "/"+p.post._id + routes[postContext.name+Ns+"Route"]+"/deleted" : routes[postContext.name+Ns+"Route"]+"/deleted"
                    },
                    role: function (p) {
                        const isAdmin = p.user && p.user._status_isFeatured;
                        const isPostsPage = ((p.page === ns && !p.pageType) || (p.page === ns && !isNaN(Number(p.pageType)) && Number(pageType) >= 1 ));
                        return (isPostsPage && isAdmin);
                    },
                    Icon: DeleteIcon,
                    onlyIcon: true,
                    featured: true,
                },
            ];
        }

        function addContentMenuItems(props) {

            const {appContext, /*statusManager*/} = props;
            const {menus, routes, /*userStatusManager*/} = appContext;

            return [
                {
                    name: menus["new"+N+"Menu"],
                    href: routes[n+"Route"] + "/new",
                    role: function (p) {
                        const isAuthor = ((p.user?._id && p.user?._id === p.post?._author) || (p.user?._id && p.user?._id === p.post?._author?._id));
                        const isAdmin = p.user && p.user._status_isFeatured;
                        return !!(isAdmin && isAuthor);
                    },
                    Icon: DescriptionIcon,
                    disableParentRoute: true,
                    order: 31,
                },
                {
                    name: function (p) {
                        const isAuthor = ((p?.user?._id && p?.user?._id === p?.post?._author) || (p?.user?._id && p?.user?._id === p?.post?._author?._id));
                        return (isAuthor) ? menus["my"+Ns+"Menu"] : menus[postContext.name+Ns+"Menu"];
                    },
                    href: function (p) {
                        return (p?.post?._id) ? "/"+p.post._id + routes[postContext.name+Ns+"Route"] : routes[postContext.name+Ns+"Route"];
                    },
                    role: function (p) {
                        const isAuthor = ((p.user?._id && p.user?._id === p.post?._author) || (p.user?._id && p.user?._id === p.post?._author?._id));
                        return (!isAuthor && p.post && p.post._status_isFeatured);
                    },
                    Icon: DescriptionIcon,
                    order: 32,
                },
            ]
        }

        function getPageName({user, post, page, /*statusManager*/}) {

            const isAdmin = user && user._status_isFeatured;
            const isAuthor = ((user?._id && user._id === post?._author) || (user?._id && user._id === post?._author?._id));
            const isAuthorOrAdmin = (isAdmin || isAuthor);
            const authorIsAdmin = post && post._status_isFeatured;

            return ((page === ns && !pageType) || (page === ns && !isNaN(Number(pageType)) && Number(pageType) >= 1 ) || (page === ns && pageType === "deleted" && isAuthorOrAdmin)) ? (isAdmin || authorIsAdmin) ? page : "notFound" : null;

        }

        return {
            ...r,
            addMenuItems,
            addContentMenuItems,
            getPageName
        }
    },
    adminMenu: function (p) {
        const name = "document";
        const defaultAdminMenu = defaultPostTypeConfig.adminMenu({...p, name});
        defaultAdminMenu.Icon = DescriptionIcon;
        defaultAdminMenu.order = 3;
        defaultAdminMenu.items[0].Icon = DescriptionOutlined;
        return defaultAdminMenu;
    }
};

export default postTypeConfig;
