import React, {useContext, useState, useEffect, useRef, useMemo} from "react";
import getUtils from "wapplr-react/dist/common/Wapp/getUtils";
import {WappContext} from "wapplr-react/dist/common/Wapp";

import messages from "../../config/constants/messages";
import labels from "../../config/constants/labels";
import titles from "../../config/constants/titles";
import routes from "../../config/constants/routes";
import menus from "../../config/constants/menus";

import {runPostTypesConfigSync} from "../../postTypes";

import {withMaterialTheme} from "../Template/withMaterial";
import Template from "../Template";
import {materialTheme, materialMediaQuery} from "./materialTheme";

import Account from "../Account";
import NotFound from "../NotFound";
import Home from "../Home";
import Logo from "default-app/dist/common/components/Logo";

import AppContext from "./context";
//import ExternalContext from "./externalContext";

import style from "./style.css";

import getMenu from "./menu";
import getTopMenu from "./topMenu";
import getFooterMenu from "./footerMenu";

import {storage as defaultLocalStorage} from "common-components/dist/common/utils/localStorage";
import {storage as defaultMemoStorage} from "common-components/dist/common/utils/memoStorage";
import Cookies from "../Cookies";

export default function App(props) {

    const context = useContext(WappContext);

    const {wapp, res} = context;
    const utils = getUtils(context);
    const {subscribe, userPostTypeName = "user"} = props;

    wapp.styles.use(style);

    const ThemedTemplate = useMemo(()=> withMaterialTheme({theme: materialTheme, mediaQuery: materialMediaQuery, classNamePrefix: wapp.globals.WAPP}, Template), [wapp.globals.WAPP]);

    const container = useRef();

    const [url, setUrl] = useState(utils.getRequestUrl());
    const [position] = useState(wapp.globals.NAME === wapp.globals.RUN ? "fixed" : "sticky");
    const [fullscreen] = useState(wapp.globals.NAME === wapp.globals.RUN);

    const storageName = wapp.globals.NAME;
    const template = useMemo(()=>{return {}}, []);

    useEffect(function (){

        async function onLocationChange(newUrl){
            if (url !== newUrl){
                await setUrl(newUrl);
            }
        }

        const unsub = subscribe.locationChange(onLocationChange);
        return function useUnsubscribe(){
            unsub();
        }
    }, [subscribe, url, template.actions]);

    const storage = function (data, memo) {
        if (memo){
            return defaultMemoStorage((data) ? data : {}, storageName);
        }
        return defaultLocalStorage(data, storageName);
    };

    const userStatusManager = wapp.getTargetObject().postTypes.findPostType({name: userPostTypeName}).statusManager;
    const route = res.wappResponse.route;
    const requestPath = route.requestPath;

    const appContext = {messages, labels, titles, routes, menus, userStatusManager, storage, template};

    const PostTypesComponent = runPostTypesConfigSync({action:"getComponent", p:{context, appContext}}).filter((C)=> !!(C))[0];

    const home = requestPath === "/";

    function onClick(e, menu) {
        console.log(menu, e)
        e.preventDefault();
        const href = menu.href;
        wapp.client.history.push({
            search: "",
            hash: "",
            ...wapp.client.history.parsePath(href)
        });
    }

    return (
        <AppContext.Provider value={appContext}>
            <div
                ref={container}
                className={style.app}
            >
                <ThemedTemplate
                    position={position}
                    fullscreen={fullscreen}
                    getMenu={getMenu}
                    getTopMenu={getTopMenu}
                    getFooterMenu={getFooterMenu}
                    Logo={()=><Logo textStyle={{height:"20px"}}/>}
                    effect={(p)=>{template.actions = p?.actions}}
                    pageContentNoPadding={home}
                    transparentAppBar={false}
                    copyright={
                        <div
                            style={{cursor: "pointer"}}
                            onClick={(e) => onClick(e, {href: "/"})}
                        >
                            <div style={{height:"16px"}}>
                            <span style={{verticalAlign: "text-top"}}>
                                <div style={{display:"inline-block", height:"72%"}}>
                                    <Logo />
                                </div>
                            </span>
                                <span style={{marginLeft:"6px"}}>{`© ${new Date().getFullYear()}`}</span>
                            </div>
                        </div>
                    }
                >
                    {
                        (home) ?
                            <Home />
                            :
                          (requestPath.startsWith(routes.accountRoute)) ?
                            <Account />
                            :
                            (PostTypesComponent) ?
                              <PostTypesComponent />
                              :
                              <NotFound />
                    }
                    <Cookies />
                </ThemedTemplate>
            </div>
        </AppContext.Provider>
    );
}
