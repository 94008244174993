import React, {useContext, useEffect, useState, useRef, useMemo} from "react";

import {WappContext, withWapp} from "wapplr-react/dist/common/Wapp";
import getUtils from "wapplr-react/dist/common/Wapp/getUtils";

import Button from "@mui/material/Button";
import {copyObject} from "wapplr/dist/common/utils";

import BrushIcon from '@mui/icons-material/Brush';
import FamilyRestroomIcon from '@mui/icons-material/FamilyRestroom';
import FAB from "@mui/material/Fab";

import Logo from "default-app/dist/common/components/Logo";
import clsx from "clsx";

import {withMaterialStyles} from "../Template/withMaterial";
import AppContext from "../App/context";

import Posts from "../Posts";

import DocumentContent from "../../postTypes/document/Content";
import PostContext from "../../components/Post/context";

import getMenu from "../../postTypes/artwork/getMenu";
import getApp from "../../postTypes/artwork/getApp";

import ArtworkContent from "../../postTypes/artwork/Content";
import artwork from "../../postTypes/artwork";

import carcoWeb from "./carco-web.jpg";
import materialStyle from "./materialStyle";
import style from "./style.css";

function Artwork({artwork, setLoaded}) {

    const context = useContext(WappContext);

    const {wapp, req} = context;

    const appSlug = artwork.appSlug;

    const _id = artwork._id;
    const url = "/artwork";
    const queryString = "";

    const [artworkApp, _setArtworkApp] = useState(null);

    async function setArtworkApp() {
        if (!artworkApp || artworkApp && artworkApp.wapp.globals.NAME !== appSlug) {
            const {createChildWapp} = await getApp(appSlug);
            const newArtworkApp = await createChildWapp({
                parentWapp: wapp,
                parentReq: req,
                parentRoute: "/",
                config: {globals: {WAPP: wapp.globals.WAPP + "_" + appSlug + "_" + _id}},
                req: {
                    path: url,
                    url: (queryString) ? url + "?" + queryString : url
                }
            });
            setLoaded(artwork)
            await _setArtworkApp(newArtworkApp)
        }
    }

    useEffect(() => {
        setArtworkApp()
    }, [appSlug])

    return artworkApp ?
        <PostContext.Provider value={{post: artwork}}>
            <ArtworkContent
                artworkApp={artworkApp}
                justContent={true}
                externalSettings={{
                    heightByParent: true,
                    disableLogo: true,
                    disableFirstAnimation: true
                }}
            />
        </PostContext.Provider>
        :
        null

}

function Slider(props) {

    const {featured = [], style} = props;
    const [index, setIndex] = useState(featured.length >= 2 ? Math.floor(Math.random() * featured.length) : 0);
    const [loaded, setLoaded] = useState(null);

    const length = featured.length;
    const prev = featured[index-1] ? index-1 : length-1;
    const next = featured[index+1] ? index+1 : 0;

    const container = useRef()

    return (
        <div className={style.slider} ref={container}>
            {featured?.length ? featured
                    .filter((a, i) => i === index)
                    .map((artwork, i) => <Artwork key={i} artwork={artwork} setLoaded={setLoaded} />)
                : null
            }
            {loaded && length > 1 ?
                <>
                    <div key={"prev"} className={style.sliderPrev} onClick={()=>setIndex(prev)} />
                    <div key={"next"} className={style.sliderNext} onClick={()=>setIndex(next)} />
                </>
                : null
            }
        </div>
    )
}

function About(props) {

    const {aboutProjectContent, style} = props;

    const [aboutType, setAboutType] = useState("artist")

    return (
        <div id={"about"} className={clsx(style.section, style.about)}>
            <div className={style.sectionTitleContainer}>
                <div className={style.sectionTitle}><div className={style.logoInTitle}><Logo /></div></div>
                <div className={style.sectionSubtitle}>{aboutProjectContent.subtitle}</div>
                <div className={style.c4rc0} >
                    <img src={carcoWeb} alt={"C4RC0"}/>
                    {
                        false && <div className={style.aboutType}>
                            <FAB
                                size={"small"}
                                color={"secondary"}
                                onClick={async ()=>await setAboutType(aboutType === "family" ? "artist" : "family")}
                            >
                                {(aboutType === "family") ? <FamilyRestroomIcon /> : <BrushIcon />}
                            </FAB>
                        </div>
                    }
                </div>
            </div>
            <div className={style.postCardsContainer}>
                <PostContext.Provider value={{post: aboutProjectContent}}>
                    <DocumentContent />
                </PostContext.Provider>
            </div>
        </div>
    )
}

function Home(props) {

    const context = useContext(WappContext);
    const appContext = useContext(AppContext);
    const {wapp} = context;
    const utils = getUtils(context);

    const {subscribe} = props;

    wapp.styles.use(style);

    const [user, setUser] = useState(utils.getRequestUser());

    function onUserChange(user){
        setUser((user?._id) ? user : null);
    }

    useEffect(function didMount(){
        const unsub = subscribe.userChange(onUserChange);
        return function willUnmount() {
            unsub();
        }
    }, [subscribe, user]);

    async function onClick(e, menu) {

        e.preventDefault();

        const href = menu.href;

        if (menu.href === "#featured"){
            window.scrollTo({behavior: "smooth", top: document.getElementById("featured").offsetTop-64-48})
        } else {
            wapp.client.history.push({
                search:"",
                hash:"",
                ...wapp.client.history.parsePath(href)
            });
        }

    }

    const container = useRef();

    const homeServices = useMemo(()=>utils.getGlobalState("res.responses.homeServices"), []);
    const services = copyObject(homeServices?.items);

    const aboutProjectContent = services && services.find((document)=>document && document.slug === "about");

    const homeFeatured = useMemo(()=>utils.getGlobalState("res.responses.homeFeatured"), []);
    const featured = copyObject(homeFeatured?.items);

    const isAdmin = user?._status_isFeatured;

    return (
        <div className={style.home} >
            <div id={"home"} className={style.first} ref={container}>
                <Slider featured={featured} style={style} />
                <div className={style.slideContent} >
                    <div className={style.centerContainer}>
                        <div className={style.buttons}>
                            {(user?._id) ?
                                <Button
                                    onClick={(e) => onClick(e, {href:appContext.routes.userRoute + "/" + user._id})}
                                    children={appContext.menus.dashboardMenu}
                                    variant={"contained"}
                                    color={"secondary"}
                                />
                                : null
                            }
                            <Button
                                onClick={(e) => onClick(e, {
                                    href:"#featured"
                                })}
                                children={appContext.menus.artworksMenu}
                                variant={"contained"}
                                color={"secondary"}
                            />
                        </div>
                    </div>
                </div>
            </div>

            {(featured?.length) ?
                <>
                    <div id={"featured"} className={style.section}>
                        <div className={style.sectionTitleContainer}>
                            <div className={style.sectionTitle}>{appContext.titles.featuredTitle}</div>
                            <div className={style.sectionSubtitle}>{appContext.titles.featuredSubtitle}</div>
                        </div>
                        <div className={style.postCardsContainer}>
                            <Posts
                                type={"card"}
                                posts={featured}
                                pageInfo={{}}
                                name={"artwork"}
                                thereAreNoEntriesMessage={""}
                                disablePageInfo={true}
                                disableAvatars={true}
                                tableProps={{className: null}}
                                getMenu={getMenu}
                                onClick={(e, post)=>{
                                    const isAuthor = ((user?._id && user?._id === post?._author) || (user?._id && user?._id === post?._author?._id));
                                    const href = (isAuthor || isAdmin) ? appContext.routes["artworkRoute"]+"/"+post._id : appContext.routes["artworkRoute"]+"/widescreen/"+post._id
                                    e.preventDefault();
                                    wapp.getTargetObject().history.push({
                                        search:"",
                                        hash:"",
                                        ...wapp.getTargetObject().history.parsePath(href)
                                    });
                                }}
                            />
                        </div>
                        <div className={style.buttons}>
                            <Button
                                onClick={(e) => onClick(e, {
                                    href: (isAdmin) ? appContext.routes.artworksRoute : appContext.routes.artworksRoute + "/categories/main"
                                })}
                                children={appContext.menus.moreArtworksMenu}
                                variant={"contained"}
                                color={"secondary"}
                            />
                        </div>
                    </div>
                </> : null
            }
            {aboutProjectContent ? <About style={style} aboutProjectContent={aboutProjectContent}/> : null}
            {(services?.length && false) ?
                <div id={"services"} className={style.section}>
                    <div className={style.sectionTitleContainer}>
                        <div className={style.sectionTitle}>{appContext.titles.servicesTitle}</div>
                        <div className={style.sectionSubtitle}>{appContext.titles.servicesSubtitle}</div>
                    </div>
                    <div className={style.postCardsContainer}>
                        <Posts
                            type={"card"}
                            posts={services}
                            pageInfo={{}}
                            name={"document"}
                            thereAreNoEntriesMessage={""}
                            disablePageInfo={true}
                            disableAvatars={true}
                            disableTable={true}
                        />
                    </div>
                </div> : null
            }

        </div>
    )
}

const WappComponent = withWapp(Home);

const StyledComponent = withMaterialStyles(materialStyle, WappComponent);

export default StyledComponent;
