import React, {useContext, useEffect, useState} from 'react';

import {WappContext, withWapp} from 'wapplr-react/dist/common/Wapp';
import getUtils from 'wapplr-react/dist/common/Wapp/getUtils';
import clsx from 'clsx';

import {withMaterialStyles} from '../../../components/Template/withMaterial';
import PostContext from '../../../components/Post/context';
import ExternalContext from '../../../components/App/externalContext';
import AppContext from '../../../components/App/context';

import materialStyle from './materialStyle';
import style from './style.css';

import * as postprocessing from 'postprocessing';
import * as THREE from 'three';
import AmmoLib from '../ammo.wasm';

import { LineMaterial } from 'three/examples/jsm/lines/LineMaterial.js'
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader.js'
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader.js'
import { TextGeometry } from "three/examples/jsm/geometries/TextGeometry.js"
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { MTLLoader } from 'three/examples/jsm/loaders/MTLLoader.js'
import { LineSegments2 } from 'three/examples/jsm/lines/LineSegments2.js'
import { Sky } from 'three/examples/jsm/objects/Sky.js'
import { Line2 } from 'three/examples/jsm/lines/Line2.js'
import { LineGeometry } from 'three/examples/jsm/lines/LineGeometry.js'
import { LineSegmentsGeometry } from 'three/examples/jsm/lines/LineSegmentsGeometry.js'
import { SVGRenderer } from 'three/examples/jsm/renderers/SVGRenderer.js'
import { OBJExporter } from 'three/examples/jsm/exporters/OBJExporter.js'
import { GLTFExporter } from 'three/examples/jsm/exporters/GLTFExporter.js'
import { STLExporter } from 'three/examples/jsm/exporters/STLExporter.js'

import getRandomFunction from "../getRandomFunction";
import parseParams from "../getParseParams";
import getControlsMenu from "../getControlsMenu";

let faceLandmarksDetection;
let timeWeb;

async function loadClientPackages() {
    await import('@mediapipe/face_mesh');
    await import('@tensorflow/tfjs-core');
    await import('@tensorflow/tfjs-backend-webgl')
    return await import('@tensorflow-models/face-landmarks-detection')
}

function Content(props) {

    const context = useContext(WappContext);
    const postContext = useContext(PostContext);
    const appContext = useContext(AppContext);
    const {name = "artwork", post} = postContext;

    const {wapp, req, res} = context;
    const utils = getUtils(context);

    const {routes} = appContext;

    wapp.styles.use(style);

    const {
        subscribe,
        //materialStyle,
        justContent,
        externalSettings = {},
    } = props;

    const artworkApp = props.artworkApp || res.wappResponse[name+"App"];

    if (!artworkApp?.res){
        res.wappResponse.status(404)
        return null;
    }

    const {store, appStateName = "APP_STATE"} = artworkApp.res.wappResponse;
    const state = store.getState();
    const stateText = `if (!window["${appStateName}"] || window["${appStateName}"] && !window["${appStateName}"].req){ window["${appStateName}"] = ${JSON.stringify(state)} }`;

    const [Artwork, setArtwork] = useState(artworkApp.Render);
    const [url, setUrl] = useState(utils.getRequestUrl());
    const [loadedArPackages, setLoadedArPackages] = useState(faceLandmarksDetection || null);
    const [loadedTimeWeb, setLoadedTimeWeb] = useState(timeWeb || null)

    wapp.styles.use({
        _getCss: function () {
            if (wapp.target === "node") {
                const css = artworkApp.wapp.styles.getCssText();
                return css[0] && css[0].cssText || "";
            }
            return "";
        }
    });

    useEffect(function () {
        const unsub = subscribe.locationChange(function onLocationChange(newUrl) {

            if (newUrl !== url) {

                const artworkApp = res.wappResponse[name+"App"];

                if (artworkApp.update && !artworkApp.updated) {
                    artworkApp.update();
                    artworkApp.updated = true;
                }

                if (artworkApp.Render && artworkApp.Render !== Artwork) {
                    setArtwork(artworkApp.Render)
                }

                setUrl(newUrl);

            }

        });
        return function useUnsubscribe(){
            unsub();
        }
    }, [url])

    useEffect(()=>{
        async function asyncFunction() {
            faceLandmarksDetection = await loadClientPackages();
            await setLoadedArPackages(faceLandmarksDetection || null);
        }
        asyncFunction();
    }, [])

    const query = req.wappRequest.query;

    useEffect(()=>{
        if (query.timeweb && !window.timeWeb) {
            async function asyncFunction() {

                const sT = setTimeout;
                const cT = clearTimeout;
                const sI = setInterval;
                const cI = clearInterval;
                const timeWeb = await import("timeweb/dist/timeweb");

                window.timeWeb = {
                    o: {
                        setTimeout: sT,
                        clearTimeout: cT,
                        setInterval: sI,
                        clearInterval: cI
                    },
                    tw: timeWeb,
                    stepTime: 1000/25,
                    ct: Date.now(),
                    playState: 0,
                    add: async (time)=>{
                        window.timeWeb.ct = Math.round(window.timeWeb.ct + time);
                        await window.timeWeb.tw.goTo(window.timeWeb.ct);
                        if (window.timeWeb.callbackStep) {
                            await window.timeWeb.callbackStep();
                        }
                    },
                    next: async (callback)=>{
                        window.timeWeb.ct = Math.round(window.timeWeb.ct + window.timeWeb.stepTime);
                        await window.timeWeb.tw.goTo(window.timeWeb.ct);
                        if (callback) {
                            await callback();
                        }
                    },
                    callbackStep: async()=>{
                        await new Promise((resolve)=>sT(resolve, 100))
                    },
                    start: async ({stopConsoleMessage, callbackStep, stepTime} = {})=> {
                        if (stopConsoleMessage){
                            window.timeWeb.stopConsoleMessage = stopConsoleMessage;
                        }
                        if (callbackStep) {
                            window.timeWeb.callbackStep = callbackStep;
                        }
                        if (stepTime) {
                            window.timeWeb.stepTime = stepTime;
                        }
                        console.log("[APP]", "TimeWeb: start animation until message:", window.timeWeb.stopConsoleMessage);
                        window.timeWeb.playState = 1;
                        const callback = async()=>{
                            if (window.timeWeb.callbackStep) {
                                await window.timeWeb.callbackStep();
                            }
                            if (window.timeWeb.playState === 1) {
                                await window.timeWeb.next(callback);
                            }
                        }
                        await window.timeWeb.next(callback);
                    },
                    stop: ()=> {
                        console.log("[APP]", "TimeWeb: stop animation");
                        window.timeWeb.playState = 0;
                    }
                }
                const c = console.log;
                console.log = (...attributes)=>{
                    if (window.timeWeb.stopConsoleMessage && window.timeWeb.stopConsoleMessage === attributes.join(" ")) {
                        window.timeWeb.stopConsoleMessage = null;
                        window.timeWeb.stop();
                    }
                    return c(...attributes);
                }

                await setLoadedTimeWeb(timeWeb || null);
            }

            asyncFunction();
        }
    }, [])

    if (artworkApp.res.wappResponse.statusCode === 404){
        res.wappResponse.status(404)
    }

    const {params} = res.wappResponse.route;
    const innerPath = "/"+"/:page/:innerPage1/:innerPage2/:innerPage3".split("/:").filter((key)=>params[key]).map((key)=>params[key]).join("/");
    const parentPath = req.wappRequest.path.split(innerPath)[0];

    return (
        <ExternalContext.Provider value={{externalSettings: {
                disableLogo: true,
                ...(post.hash) ? {
                    randomFunction: getRandomFunction(post.hash),
                    hash: post.hash,
                } : {randomFunction: getRandomFunction()},
                ...(post.generateType) ? {generateType: post.generateType} : {},
                ...(post.params) ? {params: parseParams(post.params, post.paramsDefinition)} : {},
                ...externalSettings,
                parentUrl: req.wappRequest.protocol+"://"+req.wappRequest.hostname + parentPath,
                artworkUrl:  req.wappRequest.protocol+"://"+req.wappRequest.hostname + routes[name+"Route"]+"/"+post?._id,
                getControlsMenu: (p = {})=>{
                    const {req} = context;
                    const {page} = postContext;
                    if (post._id && page !== "new" && page !== "edit") {
                        return getControlsMenu({...p, appContext, name, req, post, wapp})
                    }
                    return [];
                },
                packages: {
                    THREE,
                    postprocessing,
                    AmmoLib,
                    LineMaterial,
                    OBJLoader,
                    FontLoader,
                    TextGeometry,
                    OrbitControls,
                    MTLLoader,
                    LineSegments2,
                    Sky,
                    Line2,
                    LineSegmentsGeometry,
                    LineGeometry,
                    SVGRenderer,
                    OBJExporter,
                    GLTFExporter,
                    STLExporter,
                    faceLandmarksDetection
                }
            }}}>
            {
                (justContent && Artwork) ?
                    <div
                        className={clsx({[style.heightByParent]: externalSettings.heightByParent})}
                        id={artworkApp.wapp.getTargetObject().config.containerElementId}>
                        {(Artwork) ? Artwork : null}
                        <script dangerouslySetInnerHTML={{__html: stateText}}/>
                    </div>
                     :
                    <div className={clsx(style.post, {[style.heightByParent]: externalSettings.heightByParent})}>
                        <div
                            className={clsx(style.content, {[style.heightByParent]: externalSettings.heightByParent})}
                            id={artworkApp.wapp.getTargetObject().config.containerElementId}>
                            {(Artwork) ? Artwork : null}
                            <script dangerouslySetInnerHTML={{__html: stateText}}/>
                        </div>
                    </div>
            }
        </ExternalContext.Provider>
    )
}

const WappComponent = withWapp(Content);

const StyledComponent = withMaterialStyles(materialStyle, WappComponent);

export default StyledComponent;
