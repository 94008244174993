import React, {useContext, useRef, useEffect} from "react";

import ReactMarkdown from "react-markdown";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import syntaxStyle from "react-syntax-highlighter/dist/cjs/styles/prism/okaidia";

import {WappContext, withWapp} from "wapplr-react/dist/common/Wapp";

import {withMaterialStyles} from "../../../components/Template/withMaterial";

import materialStyle from "./materialStyle";
import style from "./style.css";
import PostContext from "../../../components/Post/context";
import Gallery from "../../../components/Gallery";
import clsx from "clsx";

const renderers = {
    code: ({language, value}) => {
        return <SyntaxHighlighter style={syntaxStyle} language={language} children={value} />
    }
};

function Content() {

    const container = useRef();
    const postContext = useContext(PostContext);
    const {post} = postContext;

    const context = useContext(WappContext);

    const {wapp} = context;

    wapp.styles.use(style);

    useEffect(function () {
        if (container.current){
            const aa = container.current.getElementsByTagName("a");
            for (let i = 0; i < aa.length; i++) {
                if (aa[i].getAttribute("href").startsWith("http")) {
                    aa[i].setAttribute("target", "_blank");
                }
            }
        }
    }, [container]);

    return (
        <div className={style.post} ref={container}>
            <div className={style.content}>
                <ReactMarkdown
                    renderers={{...renderers,
                        image:
                            (props)=>
                            {
                                return (
                                    <div className={clsx({[style.inlineGalleryImage]: (!isNaN(Number(props.alt)))})}>
                                        <Gallery gallery={post.gallery} startJustOne={(!isNaN(Number(props.alt))) ? Number(props.alt) : false} />
                                    </div>
                                )
                            }
                    }}
                >
                    {post?.content}
                </ReactMarkdown>
            </div>
        </div>
    )
}

const WappComponent = withWapp(Content);

const StyledComponent = withMaterialStyles(materialStyle, WappComponent);

export default StyledComponent;
