// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".hUoCO{font-size:14px;font-size:.875rem}.iws1T{background-color:#e7e7e7;color:rgba(0,0,0,.87);-webkit-transition:-webkit-box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;transition:-webkit-box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;-o-transition:box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;transition:box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;transition:box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, -webkit-box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;border-radius:4px;-webkit-box-shadow:rgba(0,0,0,.14) 0 1px 1px 0,rgba(0,0,0,.09) 0 0 3px 0;box-shadow:rgba(0,0,0,.14) 0 1px 1px 0,rgba(0,0,0,.09) 0 0 3px 0}._0rPfA{margin-bottom:14px}._0rPfA:last-child{margin-bottom:0}.QXiSK{background-color:#fff;padding:6px 12px;display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center}.\\+nm7O{position:absolute;width:8px;height:8px;background-color:red;border-radius:4px;top:25px;left:7px}.dvtuh{font-weight:500;padding:8px;-ms-flex:1 1;flex:1 1}.dvtuh:first-letter{text-transform:capitalize}.PTd17{padding:14px}.g2bDT .PTd17{display:none}.DeAMu{display:-ms-flexbox;display:flex;margin-bottom:14px;position:relative}.DeAMu:last-child{margin-bottom:0}.oH2dN{-ms-flex:1 1;flex:1 1;width:100%}.DVD1d{width:100%}.TPTgD{width:100%}.ON6Ld{width:100%}.On39Y{width:100%;display:-ms-flexbox;display:flex}.Bb7xC{-ms-flex:1 1;flex:1 1}._3uJfG{width:100%}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"schemaForm": "hUoCO",
	"objectField": "iws1T",
	"objectItem": "_0rPfA",
	"objectHeader": "QXiSK",
	"objectFieldErrorMark": "+nm7O",
	"objectTitle": "dvtuh",
	"objectContent": "PTd17",
	"objectClosed": "g2bDT",
	"fieldContainer": "DeAMu",
	"fieldItem": "oH2dN",
	"TextField": "DVD1d",
	"NumberField": "TPTgD",
	"textField": "ON6Ld",
	"textFieldContainer": "On39Y",
	"textFieldItem": "Bb7xC",
	"Select": "_3uJfG"
};
module.exports = ___CSS_LOADER_EXPORT___;
