"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.materialTheme = exports.materialMediaQuery = void 0;
var materialTheme = function materialTheme() {
  var p = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var _p$mediaQuery = p.mediaQuery,
    mediaQuery = _p$mediaQuery === void 0 ? {} : _p$mediaQuery;
  //const prefersDarkMode = mediaQuery["(prefers-color-scheme: dark)"];
  var printMode = mediaQuery["print"];
  return {
    colorSchemes: {
      light: {
        palette: {
          primary: {
            main: "#000000"
          },
          secondary: {
            main: "#000000"
          },
          background: {
            paper: "#ffffff"
          },
          error: {
            main: "rgba(244, 67, 54, 1)"
          }
        }
      },
      dark: {
        palette: {
          primary: {
            main: !printMode ? "#ffffff" : "#000000"
          },
          secondary: {
            main: !printMode ? "#ffffff" : "#000000"
          },
          background: {
            paper: !printMode ? "#303338" : "#ffffff"
          },
          error: {
            main: "rgba(244, 67, 54, 1)"
          }
        }
      }
    }
  };
};
exports.materialTheme = materialTheme;
var materialMediaQuery = ["(prefers-color-scheme: dark)", "print"];
exports.materialMediaQuery = materialMediaQuery;