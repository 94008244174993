import React, {useContext, useMemo, useState, useEffect, useRef} from "react";

import {WappContext} from "wapplr-react/dist/common/Wapp";
import getUtils from "wapplr-react/dist/common/Wapp/getUtils";

import ClearIcon from "@mui/icons-material/Clear";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import Typography from "@mui/material/Typography";
import ChangeCircleOutlinedIcon from "@mui/icons-material/ChangeCircleOutlined";

import capitalize from "../../utils/capitalize";

import AppContext from "../App/context";
import PostContext from "../Post/context";
import AccountContext from "../Account/context";
import Dialog from "../Dialog";

import PostsList from "../Posts";

import style from "./style.css";
import clsx from "clsx";

function Posts(props) {

    const {
        value,
        label,
        refPostType,
        multiple,
        onChange,
        disabled,
        clickIsOpen,
        thereAreNoEntriesMessage,
        enableNew,
        NewComponent,
        accept,
        disableFindByAuthor,
        type,
        initialMaxPerPage,
        disablePageInfo,
        disableTable,
        disableFab,
        helperText,
        error,
        selectDisabled,
        effect,
        getStageProps = ()=>({}),
        getDialogProps = ()=>({}),
    } = props;

    const name = refPostType;

    const n = name;
    const N = capitalize(n);
    const Ns = (N.endsWith("y")) ? N.slice(0,-1)+"ies" : N+"s";

    const context = useContext(WappContext);
    const appContext = useContext(AppContext);
    const postContext = useContext(PostContext);
    const accountContext = useContext(AccountContext);

    const {wapp, req, res} = context;
    const utils = getUtils(context);

    wapp.styles.use(style);

    const user = postContext.user || accountContext.user;
    const post = postContext.post || accountContext.user;

    const defaultValue = typeof value === "object" && value && typeof value.length === "number" ? [...value] : (value && value._id) ? [value] : [];
    const [posts, setPosts] = useState(defaultValue);

    const postsIdsString = useMemo(()=>posts.map((post)=>post._id).join(""), [posts]);

    const dialog = useMemo(()=>{return {}}, []);
    const dialogEffect = useMemo(() => ({actions}) => {dialog.actions = actions;}, [dialog]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const selectedIdsForPosts = useMemo(()=>posts.map((post)=>post._id), [postsIdsString]);

    const NewPage = useMemo(()=>(enableNew && NewComponent) || null, [enableNew, NewComponent]);

    const onDeleteClick = useMemo(()=> async (e, post)=> {
        e.preventDefault();
        const np = posts.filter((p)=>p._id !== post._id);
        const value = (multiple) ? [...np.filter((p)=>p._id)] : (np[np.length-1]) ? np[np.length-1] : null;
        await setPosts((value) ? (value._id) ? [value] : value  : []);
        onChange({...e, target: {...e.target, value}})
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [multiple, onChange, postsIdsString]);

    const onSuccess = useMemo(()=> async function onSuccess(e, newPosts) {
        const oldPosts = (type === "simpleCheckboxes") ? [] : posts;
        const np = [...oldPosts, ...(newPosts && newPosts.length) ? newPosts : []];
        const uniqueIds = [...new Set([...oldPosts.map((p)=>p._id), ...(newPosts && newPosts.length) ? newPosts.map((p)=>p._id) : []])];
        const up = uniqueIds.map((id)=>np.find((p)=>id===p._id));
        const value = (multiple) ? up : (up[up.length-1] ) ? up[up.length-1] : null;
        await setPosts((value) ? (value._id) ? [value] : value  : []);
        onChange({...e, target: {...e.target, value}});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [multiple, onChange, postsIdsString]);

    const initialAuthor = useMemo(()=>props.author || post?._author || user, [post, user, props.author]);
    const [authorId, setAuthorId] = useState( (initialAuthor?._id) ? initialAuthor?._id : (typeof initialAuthor === "string") ? initialAuthor : null);

    useEffect(()=>{
        if (props.author?._id && props.author._id !== authorId){
            setAuthorId(props.author._id);
        }
    }, [props.author, authorId]);

    const getPostsAndPageInfo = useMemo(()=>function getPostsAndPageInfo(response){
        if (response && response[n+"FindMany"]){
            response = response[n+"FindMany"];
        }
        const posts = response?.items || [];
        const pageInfo = response?.pageInfo || {};
        return {posts, pageInfo};
    }, [n]);

    const postsRef = useMemo(()=>({actions: {}}), []);

    const postType = wapp.getTargetObject().postTypes.findPostType({name: n});
    const postStatusManager = postType.statusManager;

    const createRequestParams = useMemo(()=>function createRequestParams(params = {}) {

        const {paginationNumber, searchText} = params;

        const defaultFilter = {
            ...(disableFindByAuthor) ? {} : {_author: authorId},
            _operators:{
                _status: {gt: postStatusManager.getMinStatus() - 1},
            },
        };

        const listData = utils.getGlobalState("res.graphql.query."+name+"FindMany.listData") || {};
        const listDataSort = listData.sort || [];

        if (searchText) {
            listDataSort.push({key: "TEXTSCORE"})
        }

        const defaultSort = listDataSort[0]?.key || "";

        let sort = (params.sort && listDataSort && listDataSort.map((p)=>p.key).find((key)=>key === params.sort)) || defaultSort;

        if (sort === "TEXTSCORE") {
            sort = "";
        }

        const perPageFormData = listData.perPage;
        const limitPerPage = perPageFormData.limit || 100;
        const defaultPerPage = (initialMaxPerPage) ? limitPerPage : (perPageFormData.default || 20);

        const perPage = (params.perPage && !isNaN(Number(params.perPage)) && Number(params.perPage) <= limitPerPage && Number(params.perPage) > 1) ? Number(params.perPage) : defaultPerPage;

        const defaultArgs = {
            ...(sort) ? {sort} : {},
            page: (typeof paginationNumber !== "undefined") ? paginationNumber : 1,
            perPage
        };

        return {
            requestName: n+"FindMany",
            args: accept ? {
                filter: {
                    ...(searchText) ? {search: searchText} : {},
                    OR: accept.split(",").map((mimeType)=>{
                        return {
                            ...defaultFilter,
                            mimeType
                        }
                    })
                },
                ...defaultArgs
            } : {
                filter: {
                    ...(searchText) ? {search: searchText} : {},
                    ...defaultFilter
                },
                ...defaultArgs
            },
            req,
            res
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [disableFindByAuthor, authorId, postStatusManager, name, n, accept, req, res]);

    const open = useMemo(()=> (disabled && type === "simpleCheckboxes") ? async ()=>null : async (e) => {

        e.preventDefault();

        if (dialog.actions){

            let searchText = "";
            let perPage;
            let sort;

            const response = await wapp.requests.send(createRequestParams({paginationNumber: 1, searchText}));
            const {posts, pageInfo} = getPostsAndPageInfo(response);

            dialog.actions.open({
                dialogTitle: (multiple) ? appContext.titles["select"+Ns] : appContext.titles["select"+N],
                dialogContent: <>
                    {(NewPage) ?
                        <NewPage
                            name={name}
                            onSuccess={async ({files})=>{
                                const newFiles = files && files.length && files.filter((file)=>file && file._id);
                                if (newFiles.length) {
                                    await new Promise((resolve)=>setTimeout(resolve, 500));
                                    await onSuccess(e, newFiles);
                                }
                            }}
                            dropZone={false}
                            multiple={multiple}
                            accept={accept}
                            args={(authorId) ? {_author: authorId} : {}}
                        />
                        : null
                    }
                    <PostsList
                        name={name}
                        disableSearch={false}
                        thereAreNoEntriesMessage={thereAreNoEntriesMessage}
                        searchOnChange={async (p)=>{
                            if (searchText !== p.searchText) {
                                searchText = p.searchText;
                                sort = "TEXTSCORE";
                                const response = await wapp.requests.send(createRequestParams({paginationNumber: 1, searchText, sort, perPage}));
                                const {posts, pageInfo} = getPostsAndPageInfo(response);
                                await postsRef.actions.setPosts(posts);
                                await postsRef.actions.setPageInfo(pageInfo);
                                await postsRef.actions.scrollToTop();
                            }
                        }}
                        sortOnChange={async (p)=>{
                            if (sort !== p.sort) {
                                sort = p.sort;
                                const response = await wapp.requests.send(createRequestParams({paginationNumber: 1, searchText, sort, perPage}));
                                const {posts, pageInfo} = getPostsAndPageInfo(response);
                                await postsRef.actions.setPosts(posts);
                                await postsRef.actions.setPageInfo(pageInfo);
                                await postsRef.actions.scrollToTop();
                            }
                        }}
                        perPageOnChange={async (p)=>{
                            if (perPage !== p.perPage) {
                                perPage = p.perPage;
                                const response = await wapp.requests.send(createRequestParams({paginationNumber: 1, searchText, sort, perPage}));
                                const {posts, pageInfo} = getPostsAndPageInfo(response);
                                await postsRef.actions.setPosts(posts);
                                await postsRef.actions.setPageInfo(pageInfo);
                                await postsRef.actions.scrollToTop();
                            }
                        }}
                        getSearchText={()=>searchText}
                        getPerPage={()=>perPage}
                        getSort={()=>sort}
                        selectable={true}
                        multiple={multiple}
                        getMenu={()=>[]}
                        onClickIsSelect={true}
                        effect={({actions})=>{
                            dialog.actions.getSelectedPosts = actions.getSelectedPosts;
                            postsRef.actions = actions;
                        }}
                        disableAvatars={true}
                        selectDisabled={({post, selected})=>{
                            const disabled = (multiple && selectedIdsForPosts.indexOf(post._id) > -1) || (!multiple && selected);
                            if (!disabled && selectDisabled){
                                return selectDisabled({post, selected});
                            }
                            return disabled;
                        }}
                        selected={selectedIdsForPosts}
                        paginationOnClick={async (e, {page})=>{
                            const response = await wapp.requests.send(createRequestParams({paginationNumber:page, searchText, sort, perPage}));
                            const {posts, pageInfo} = getPostsAndPageInfo(response);
                            await postsRef.actions.setPosts(posts);
                            await postsRef.actions.setPageInfo(pageInfo);
                            await postsRef.actions.scrollToTop();
                        }}
                        getScrollElement={()=>dialog.actions.getScrollElement()}
                        posts={posts}
                        pageInfo={pageInfo}
                        disablePageInfo={disablePageInfo}
                        disableTable={(disableTable === true || disableTable === "dialog")}
                        {...getDialogProps()}
                    />
                </>,
                cancelText: appContext.labels["cancel"+N+"Text"],
                submitText: (multiple) ? appContext.labels["add"+N+"Text"] : appContext.labels["select"+N+"Text"],
                onSubmit: async (e)=>{
                    e.preventDefault();
                    const selectedPosts = [...(dialog.actions.getSelectedPosts) ? dialog.actions.getSelectedPosts(true) : []];
                    await onSuccess(e, selectedPosts);
                    await dialog.actions.close();
                },
            })

        }
    }, [disabled, type, dialog.actions, wapp.requests, createRequestParams, getPostsAndPageInfo, multiple, appContext.titles, appContext.labels, Ns, N, NewPage, name, accept, authorId, thereAreNoEntriesMessage, selectedIdsForPosts, disablePageInfo, disableTable, onSuccess, postsRef, getDialogProps, selectDisabled]);

    const container = useRef();

    const simpleCheckboxesChildren = useMemo(()=>{
        return (type === "simpleCheckboxes") ?
            <SimpleCheckboxes {...{
                wapp,
                name,
                thereAreNoEntriesMessage,
                disabled,
                createRequestParams,
                getPostsAndPageInfo,
                multiple,
                container,
                selectedPosts: posts,
                onSelect: onSuccess,
                disablePageInfo,
                disableTable,
                getStageProps
            }}/> : null
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [type, wapp, name, thereAreNoEntriesMessage, disabled, createRequestParams, getPostsAndPageInfo, multiple, postsIdsString, onSuccess, disablePageInfo, disableTable]);

    const defaultRemoveMenu = {
        name: "Remove item",
        onClick: (clickIsOpen) ? open : (e) => onDeleteClick(e, post),
        Icon: (clickIsOpen) ? ChangeCircleOutlinedIcon : ClearIcon,
        onlyIcon: true,
        featured: true
    };

    useEffect(()=>{
       if (effect){
           effect({actions:{getPosts: ()=>posts}})
       }
       return ()=>{
           if (effect){
               effect({actions:{getPosts: ()=>posts}})
           }
       }
    });

    return (
        <div className={clsx(style.posts, {[style.error]: error})} ref={container}>
            {(label) ? <div className={style.label}>{label}</div> : null}
            <div className={style.postsList}>
                {(type === "simpleCheckboxes") ?
                    simpleCheckboxesChildren :
                    <PostsList
                        posts={posts}
                        pageInfo={{}}
                        name={name}
                        disablePageInfo={true}
                        disableTable={(disableTable === true || disableTable === "stage")}
                        thereAreNoEntriesMessage={thereAreNoEntriesMessage}
                        disableAvatars={true}
                        onClick={(disabled) ? () => null : (clickIsOpen) ? open : onDeleteClick}
                        thereAreNoEntriesClick={(disableFab && !disabled) ? open : null}
                        getMenu={
                            (!disabled) ? ({post}) => [{
                                name: "Remove item",
                                onClick: (clickIsOpen) ? open : (e) => onDeleteClick(e, post),
                                Icon: (clickIsOpen) ? ChangeCircleOutlinedIcon : ClearIcon,
                                onlyIcon: true,
                                featured: true
                            }]
                            : () => []
                        }
                        {...getStageProps({defaultRemoveMenu})}
                    />
                }
                {(!disabled && type !== "simpleCheckboxes" && !disableFab) ?
                    <div className={style.postsFabContainer}>
                        <Fab onClick={open}>
                            <AddIcon/>
                        </Fab>
                    </div>
                    : null
                }
                {(!disabled) ? <Dialog effect={dialogEffect} /> : null}
                <fieldset aria-hidden="true"
                          className={style.fieldset}
                >
                    <legend
                        className={style.legend}
                        style={{...(!label) ? {width:0} : {}}}
                    >
                        <span>{label}</span>
                    </legend>
                </fieldset>
            </div>
            {
                helperText ?
                    <Typography
                        className={clsx(style.helperText, {[style.error]: error})}
                        color={(error) ? "error" : null}
                    >
                        {helperText}
                    </Typography>
                    : null
            }
        </div>
    )
}

function SimpleCheckboxes(props) {

    const {
        wapp,
        name,
        thereAreNoEntriesMessage,
        disabled,
        createRequestParams,
        getPostsAndPageInfo,
        multiple,
        container,
        onSelect,
        disablePageInfo,
        disableTable,
        getStageProps
    } = props;

    const defaultRequestProps = createRequestParams();

    const propsSelectedPostsIds = useMemo(()=>(props.selectedPosts?.length) ? props.selectedPosts.map((post)=>post._id) : [], [props.selectedPosts]);

    const [requestPropsAndSelectedPosts, setRequestPropsAndSelectedPosts] = useState({
        searchText: "",
        paginationNumber:1,
        perPage: defaultRequestProps.args.perPage,
        sort: defaultRequestProps.args.sort,
        selectedPosts: propsSelectedPostsIds
    });

    const [postsAndPageInfo, _setPostsAndPageInfo] = useState({});

    let setPostsAndPageInfo = async function (v) {
        await _setPostsAndPageInfo(v)
    };

    const {posts, pageInfo} = postsAndPageInfo;

    const {
        searchText,
        paginationNumber,
        perPage,
        sort,
        selectedPosts
    } = requestPropsAndSelectedPosts;

    const propsSelectedPostsIdsString = propsSelectedPostsIds.join("");
    const selectedPostsString = selectedPosts.join("");

    useEffect(()=>{
        if (propsSelectedPostsIdsString !== selectedPostsString){
            setRequestPropsAndSelectedPosts({
                searchText,
                paginationNumber,
                perPage,
                sort,
                selectedPosts: propsSelectedPostsIds
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [propsSelectedPostsIdsString, selectedPostsString]);

    const request = useMemo(()=>async function request() {
        const response = await wapp.requests.send(createRequestParams({searchText, perPage, paginationNumber, sort}));
        const {posts, pageInfo} = getPostsAndPageInfo(response);
        await setPostsAndPageInfo({posts, pageInfo});
    }, [createRequestParams, getPostsAndPageInfo, paginationNumber, perPage, searchText, sort, wapp.requests]);

    useEffect(()=>{
       request();
       return () =>{
           setPostsAndPageInfo = async function () {};
        }
    }, [request]);

    const postsRef = useMemo(()=>({actions: {}}), []);

    return (
        <PostsList
            thereAreNoEntriesMessage={thereAreNoEntriesMessage}
            disableAvatars={true}
            selectDisabled={()=>disabled}
            getMenu={()=>[]}
            name={name}
            disableSearch={false}
            searchOnChange={async (p)=>{
                if (searchText !== p.searchText) {
                    const searchText = p.searchText;
                    const sort = "TEXTSCORE";
                    const paginationNumber = 1;
                    const selectedPosts = postsRef.actions.getSelectedIds(true);
                    await setRequestPropsAndSelectedPosts({paginationNumber, searchText, sort, perPage, selectedPosts});
                }
            }}
            sortOnChange={async (p)=>{
                if (sort !== p.sort) {
                    const sort = p.sort;
                    const paginationNumber = 1;
                    const selectedPosts = postsRef.actions.getSelectedIds(true);
                    await setRequestPropsAndSelectedPosts({paginationNumber, searchText, sort, perPage, selectedPosts});
                }
            }}
            perPageOnChange={async (p)=>{
                if (perPage !== p.perPage) {
                    const perPage = p.perPage;
                    const paginationNumber = 1;
                    const selectedPosts = postsRef.actions.getSelectedIds(true);
                    await setRequestPropsAndSelectedPosts({paginationNumber, searchText, sort, perPage, selectedPosts});
                }
            }}
            getSearchText={()=>searchText}
            getPerPage={()=>perPage}
            getSort={()=>sort}
            selectable={true}
            multiple={multiple}
            onClickIsSelect={true}
            effect={({actions})=>{
                postsRef.actions = actions;
            }}
            selected={selectedPosts}
            paginationOnClick={async (e, {page})=>{
                const paginationNumber = page;
                const selectedPosts = postsRef.actions.getSelectedIds(true);
                await setRequestPropsAndSelectedPosts({paginationNumber, searchText, sort, perPage, selectedPosts});
            }}
            getScrollElement={()=>container.current}
            posts={posts}
            pageInfo={pageInfo}
            onSelect={async (e, actions)=>{
                postsRef.actions = actions;
                if (onSelect) {
                    const selectedPosts = postsRef.actions.getSelectedPosts(true);
                    await onSelect(e, selectedPosts);
                } else {
                    const selectedPosts = postsRef.actions.getSelectedIds(true);
                    await setRequestPropsAndSelectedPosts({paginationNumber, searchText, sort, perPage, selectedPosts});
                }
            }}
            SearchFormElement={
                (props)=>
                    <div
                        onKeyDown={(e)=>{
                            if (e.key === "Enter"){
                                e.preventDefault();
                                if (props.onSubmit){
                                    props.onSubmit(e);
                                }
                            }
                        }}
                        {...props}
                        onSubmit={undefined}
                        children={undefined}
                    >
                        {props.children}
                    </div>
            }
            disablePageInfo={disablePageInfo}
            disableTable={disableTable}
            {...getStageProps()}
        />
    )

}

const PostsWithMemo = React.memo(Posts, (prevProps, nextProps)=>{
    return !(
        (prevProps.value && nextProps.value && JSON.stringify(prevProps.value) !== JSON.stringify(nextProps.value)) ||
        (prevProps.value && !nextProps.value) ||
        (!prevProps.value && nextProps.value) ||
        (prevProps.error !== nextProps.error) ||
        (prevProps.helperText !== nextProps.helperText)  ||
        (prevProps.author?._id !== nextProps.author?._id)
    );
});

export default PostsWithMemo;
