export function getConfig(p = {}) {

    const {config = {}} = p;

    const commonConfig = config.common || {};
    const globalsConfig = config.globals || {};

    const {WAPP, DEV} = globalsConfig;

    const common = {
        ...commonConfig,
        siteName: "C4RC0",
        description: "Piliscsaba based, 37 years of age, a programmer and artist",
        graphql: {
            route: (DEV) ? "/graphql" : "/g" + WAPP
        }
    };

    return {
        config: {
            ...config,
            common: common
        },
    }
}
