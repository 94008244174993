import React, {useContext, useState, useEffect, useRef, useMemo} from "react";

import {WappContext, withWapp} from "wapplr-react/dist/common/Wapp";
import getUtils from "wapplr-react/dist/common/Wapp/getUtils";

import Menu from "../Menu";
import AppContext from "../App/context";
import {withMaterialStyles} from "../Template/withMaterial";

import Typography from "@mui/material/Typography";
import MaterialList from "@mui/material/List";
import MaterialListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import ListItemText from "@mui/material/ListItemText";
import MaterialCard from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import IconButton from "@mui/material/IconButton";
import MaterialTable from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";

import CheckBoxOutlined from "@mui/icons-material/CheckBoxOutlined";
import CheckBoxOutlineBlankOutlined from "@mui/icons-material/CheckBoxOutlineBlankOutlined";
import HideImageOutlinedIcon from "@mui/icons-material/HideImageOutlined";
import DoneAllIcon from "@mui/icons-material/DoneAll";

import clsx from "clsx";

import getStatus from "../../utils/getStatus";
import capitalize from "../../utils/capitalize";

import Avatar from "../Avatar";
import Dialog from "../Dialog";
import Pagination from "../ListTools/Pagination";
import ListTools from "../ListTools";
import PostContext from "../Post/context";
import getDefaultMenu, {getMenuProps as getDefaultMenuProps} from "../Post/menu";
import TableForItems, {valueToTableData} from "../Table";

import materialStyle from "./materialStyle";
import defaultStyle from "./style.css";
import Chip from "@mui/material/Chip";

function CardItem(props) {

    const {
        i,
        style,
        materialStyle,
        post,
        avatarClick,
        appContext,
        menu,
        menuActions,
        name,
        user,
        getTitle,
        status,
        contentStatus,
        onClick,
        refs,
        isSelected,
        showThumbnails,
        showAvatars,
        showSubtitles,
        showTitles,
        showContents,
        enableAuthorOnContent,
        enableTitleOnContent,
        enableSubtitleOnContent,
        selectDisabled,
        tableData,
        tableProps,
        statusManager,
        getFooterAction
    } = props;

    const hideHeader = (!showAvatars && !showSubtitles && !showTitles && !status);

    const [selected, setSelected] = useState(isSelected && isSelected(post._id));

    const onSelect = useMemo(()=> (e, post)=>{
        if (selectDisabled && selectDisabled({post, selected})){
            return;
        }
        setSelected(!selected);
        props.onSelect(e, post);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selected, props.onSelect]);

    useEffect(()=> {
        if (refs) {
            refs[post._id] = {
                deselect: () => setSelected(false),
                select: () => setSelected(true),
                toggle: () => setSelected(!selected)
            };
        }
        return ()=>{
            if (refs) {
                refs[post._id] = {
                    deselect: () => () => null,
                    select: () => () => null,
                    toggle: () => () => null
                };
            }
        }
    }, [selected, refs, post._id]);

    return (
        <div className={clsx(
            style.cardItem,
            {
                [style.showThumbnail] : showThumbnails,
                [style.showAvatar] : showAvatars,
                [style.thereIsAuthor] : post._author,
            }
        )}>
            <MaterialCard
                classes={{
                    container: clsx(materialStyle.cardItem, style.cardInner),
                    root: style.cardItemRoot
                }}
            >
                <CardHeader
                    titleTypographyProps={
                        {variant: "body2"}
                    }
                    subheaderTypographyProps={
                        {variant: "body2"}
                    }
                    avatar={
                        (showAvatars) ?
                            <div className={style.avatarContainer} onClick={(post._author) ? avatarClick : null}>
                                {(post._author) ? <Avatar user={(post._author && post._author === post._id) ? post : post._author}/> : <div />}
                            </div>
                            : null
                    }
                    action={
                        ((hideHeader && showThumbnails) || (!hideHeader)) ?
                            <Menu
                                parentRoute={appContext.routes[name + "Route"]}
                                menu={menu}
                                menuProperties={{user, post}}
                                effect={function ({actions}) {
                                    menuActions.actions = actions;
                                }}
                                menuKey={"menu_" + appContext.routes[name + "Route"] + "/" + post._id + "_" + i}
                            /> : null
                    }
                    title={
                        (showTitles) ?
                        <div
                            className={style.title}
                            onClick={(e) => onClick(e, post)}
                        >
                            {getTitle()}
                        </div> : null
                    }
                    subheader={
                        <>
                            {(showSubtitles && post.subtitle) ?
                                <span
                                    className={clsx(style.block, style.subtitle)}
                                    onClick={(e) => onClick(e, post)}
                                >
                                            {post.subtitle}
                                        </span> : null
                            }
                            {(status) ?
                                <span
                                    className={clsx(style.block, style.statusText)}
                                    onClick={(e) => onClick(e, post)}
                                >
                                                {status}
                                            </span>
                                : null
                            }
                        </>
                    }
                    classes={{
                        root: clsx(
                            style.cardHeader,
                            {[style.hideCardHeader]: hideHeader}
                        ),
                        content: style.cardHeaderContent,
                        action: clsx(
                            style.cardActionContainer,
                            {[style.cardActionContainerAbsolute]: hideHeader && showThumbnails}
                        )
                    }}
                />
                {
                    (showThumbnails) ?
                        <CardMedia
                            className={style.cardMedia}
                            onClick={(e) => onClick(e, post)}
                        >
                            {(post.thumb) ? <img className={style.thumb} src={post.thumb} alt={post.title}/> : <div className={style.emptyThumb}><HideImageOutlinedIcon /></div>}
                        </CardMedia>
                        : null
                }
                <div className={style.cardContentContainer}>
                    {((showAvatars && enableAuthorOnContent) || enableTitleOnContent || (enableSubtitleOnContent && post.subtitle) || contentStatus) ?
                        <div className={style.cardContentHeader}>
                            {(showAvatars && enableAuthorOnContent) ?
                                <div className={style.avatarContainer} onClick={(post._author) ? avatarClick : null}>
                                    {(post._author) ?
                                        <Avatar user={(post._author && post._author === post._id) ? post : post._author}/> :
                                        <div/>}
                                </div>
                                : null
                            }
                            {(enableTitleOnContent || (enableSubtitleOnContent && post.subtitle) || contentStatus) ?
                                <div className={style.cardContentTitleContainer}>
                                    <Typography component={"div"}>
                                        {(enableTitleOnContent) ?
                                            <div
                                                className={style.cardTitleOnContent}
                                                onClick={(e) => onClick(e, post)}
                                            >
                                                {getTitle()}
                                            </div> : null
                                        }
                                        <Typography
                                            variant={"body2"}
                                            color={"text.secondary"}
                                            component={"span"}
                                            display={"block"}
                                        >
                                            {(enableSubtitleOnContent && post.subtitle) ?
                                                <span
                                                    className={clsx(style.block, style.subtitle)}
                                                    onClick={(e) => onClick(e, post)}
                                                >
                                            {post.subtitle}
                                        </span> : null
                                            }
                                            {(contentStatus) ?
                                                <span
                                                    className={clsx(style.block, style.statusText)}
                                                    onClick={(e) => onClick(e, post)}
                                                >
                                                {contentStatus}
                                            </span>
                                                : null
                                            }
                                        </Typography>
                                    </Typography>
                                </div> : null
                            }
                        </div> : null
                    }
                    {(showContents && post.content_extract) ?
                        <CardContent classes={{root: style.cardContentRoot}}>
                            <Typography
                                variant={"body2"}
                                color={"textSecondary"}
                                component={"p"}
                                className={style.cardContent}
                                onClick={(e) => onClick(e, post)}
                            >
                                {post.content_extract}
                            </Typography>
                        </CardContent>
                        : null
                    }
                    {(tableData && Object.keys(tableData).length) ?
                        <div className={style.cardTable}>
                            <TableForItems
                                tableData={tableData}
                                post={post}
                                user={user}
                                name={name}
                                statusManager={statusManager}
                                className={style.tableNarrow}
                                labelClassName={style.tableLabelClassName}
                                labelTextClassName={style.tableLabelTextClassName}
                                {...tableProps ? tableProps : {}}
                            />
                        </div>
                        : null
                    }

                </div>
                <div
                    className={style.cardFooterActions}
                >
                    {(isSelected) ?
                        <IconButton
                            disabled={!!(selectDisabled && selectDisabled({post, selected}))}
                            onClick={(e)=>onSelect(e, post)}
                            classes={{
                                root: materialStyle.cardItemIcon,
                                disabled: materialStyle.cardItemIconDisabled
                            }}
                        >
                            {(selected) ? <CheckBoxOutlined color={"secondary"}/> : <CheckBoxOutlineBlankOutlined />}
                        </IconButton> : null
                    }
                    {(hideHeader && !showThumbnails) ?
                        <Menu
                            parentRoute={appContext.routes[name + "Route"]}
                            menu={menu}
                            menuProperties={{user, post}}
                            effect={function ({actions}) {
                                menuActions.actions = actions;
                            }}
                            menuKey={"menu_" + appContext.routes[name + "Route"] + "/" + post._id + "_" + i}
                        /> : null
                    }
                    {getFooterAction ? getFooterAction({user, post}) : null}
                </div>
            </MaterialCard>
        </div>
    )
}

function ListItem(props) {

    const {
        i,
        refs,
        style,
        materialStyle,
        showThumbnails,
        showAvatars,
        showSubtitles,
        showTitles,
        post,
        onClick,
        avatarClick,
        status,
        appContext,
        menu,
        user,
        name,
        menuActions,
        isSelected,
        getTitle,
        selectDisabled,
        tableData,
        tableProps,
        statusManager,
    } = props;

    const [selected, setSelected] = useState(isSelected && isSelected(post._id));

    const onSelect = useMemo(()=> (e, post)=>{
        if (selectDisabled && selectDisabled({post, selected})){
            return;
        }
        setSelected(!selected);
        props.onSelect(e, post);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selected, props.onSelect]);

    useEffect(()=> {
        if (refs) {
            refs[post._id] = {
                deselect: () => setSelected(false),
                select: () => setSelected(true),
                toggle: () => setSelected(!selected)
            };
        }
        return ()=>{
            if (refs) {
                refs[post._id] = {
                    deselect: () => () => null,
                    select: () => () => null,
                    toggle: () => () => null
                };
            }
        }
    }, [selected, refs, post._id]);

    return (
        <MaterialListItem
            classes={{
                container: clsx(
                    materialStyle.listItem,
                    style.listItem,
                    {
                        [style.showThumbnail] : showThumbnails,
                        [style.showAvatar] : showAvatars,
                        [style.thereIsAuthor] : post._author,
                    }
                ),
                root: style.listItemRoot
            }}
            onClick={(e) => onClick(e, post)}
        >
            <div className={style.listItemContentContainer}>
                {
                    (showThumbnails) ?
                        <CardMedia
                            className={style.cardMedia}
                            onClick={(e) => onClick(e, post)}
                        >
                            {(post.thumb) ? <img className={style.thumb} src={post.thumb} alt={post.title}/> : <div className={style.emptyThumb}><HideImageOutlinedIcon /></div>}
                        </CardMedia>
                        : null
                }
                {
                    (showAvatars) ?
                        <ListItemAvatar>
                            <div className={style.avatarContainer} onClick={(post._author) ? avatarClick : null}>
                                {(post._author) ? <Avatar user={(post._author && post._author === post._id) ? post : post._author}/> : <div />}
                            </div>
                        </ListItemAvatar>
                        : null
                }
                <ListItemText
                    primary={(showTitles) ? getTitle() || "No title" : null}
                    className={style.listItemText}
                    secondary={
                        <React.Fragment>
                            {(showSubtitles && post.subtitle) ? <span className={style.block}>{post.subtitle}</span> : null}
                            {(status) ? <span className={style.block}>{status}</span> : null}
                        </React.Fragment>
                    }
                    classes={{
                        primary: style.title,
                        secondary: style.subtitle,
                    }}
                />
            </div>
            {(tableData && Object.keys(tableData).length) ?
                <div className={style.listTable}>
                    <TableForItems
                        tableData={tableData}
                        post={post}
                        user={user}
                        name={name}
                        statusManager={statusManager}
                        className={style.tableNarrow}
                        labelClassName={style.tableLabelClassName}
                        labelTextClassName={style.tableLabelTextClassName}
                        {...tableProps ? tableProps : {}}
                    />
                </div>
                : null
            }
            <ListItemSecondaryAction>
                <Menu
                    parentRoute={appContext.routes[name+"Route"]}
                    menu={menu}
                    menuProperties={{user, post}}
                    effect={function ({actions}) {
                        menuActions.actions = actions;
                    }}
                    menuKey={"menu_"+appContext.routes[name+"Route"]+"/"+post._id+"_"+i}
                />
                {(isSelected) ?
                    <IconButton
                        disabled={!!(selectDisabled && selectDisabled({post, selected}))}
                        onClick={(e)=>onSelect(e, post)}
                        classes={{
                            root: materialStyle.listItemIcon,
                            disabled: materialStyle.listItemIconDisabled
                        }}
                    >
                        {(selected) ? <CheckBoxOutlined color={"secondary"}/> : <CheckBoxOutlineBlankOutlined />}
                    </IconButton> : null
                }
            </ListItemSecondaryAction>
        </MaterialListItem>
    )
}

function TableItem(props) {

    const {
        i,
        refs,
        style,
        materialStyle,
        showThumbnails,
        showAvatars,
        showSubtitles,
        showTitles,
        post,
        onClick,
        avatarClick,
        status,
        appContext,
        menu,
        user,
        name,
        menuActions,
        isSelected,
        getTitle,
        selectDisabled,
        tableData,
        statusManager,
        tableProps
    } = props;

    const [selected, setSelected] = useState(isSelected && isSelected(post._id));

    const onSelect = useMemo(()=> (e, post)=>{
        if (selectDisabled && selectDisabled({post, selected})){
            return;
        }
        setSelected(!selected);
        props.onSelect(e, post);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selected, props.onSelect]);

    useEffect(()=> {
        if (refs) {
            refs[post._id] = {
                deselect: () => setSelected(false),
                select: () => setSelected(true),
                toggle: () => setSelected(!selected)
            };
        }
        return ()=>{
            if (refs) {
                refs[post._id] = {
                    deselect: () => () => null,
                    select: () => () => null,
                    toggle: () => () => null
                };
            }
        }
    }, [selected, refs, post._id]);

    const authorObject = (post._author && post._author === post._id) ? post : post._author;

    return (
        <TableRow
            classes={{
                root: clsx(
                    style.tableItemRoot,
                    materialStyle.tableItem,
                    style.tableItem,
                    {
                        [style.showThumbnail] : showThumbnails,
                        [style.showAvatar] : showAvatars,
                        [style.thereIsAuthor] : post._author,
                    }
                ),
            }}
            onClick={(e) => onClick(e, post)}
        >
            {
                (showThumbnails) ?
                    <TableCell className={style.cardMedia} align={"left"}>
                        <div>
                            {(post.thumb) ? <img className={style.thumb} src={post.thumb} alt={post.title}/> : <div className={style.emptyThumb}><HideImageOutlinedIcon /></div>}
                        </div>
                    </TableCell>
                    : null
            }
            {
                (showAvatars) ?
                    <TableCell align={"left"} className={style.avatarContainer}>
                        <div onClick={(post._author) ? (e)=>{e.preventDefault(); e.stopPropagation(); avatarClick(e)} : null}>
                            {(authorObject) ?
                                <Chip
                                    avatar={<Avatar user={authorObject} />}
                                    label={authorObject.title}
                                    variant={"outlined"}
                                    color={"secondary"}
                                /> : <div />
                            }
                        </div>
                    </TableCell>
                    : null
            }
            {showTitles ?
                <TableCell
                    align={"left"}
                    className={style.tableItemTitle}
                >
                    <ListItemText
                        primary={(showTitles) ? getTitle() || "No title" : null}
                        className={style.listItemText}
                        secondary={
                            <React.Fragment>
                                {(showSubtitles && post.subtitle) ?
                                    <span className={style.block}>{post.subtitle}</span> : null}
                                {(status) ? <span className={style.block}>{status}</span> : null}
                            </React.Fragment>
                        }
                        classes={{
                            primary: style.title,
                            secondary: style.subtitle,
                        }}
                    />
                </TableCell>
                : null
            }
            {(tableData && Object.keys(tableData).length) ?
                <TableForItems
                    tableData={tableData}
                    post={post}
                    user={user}
                    name={name}
                    statusManager={statusManager}
                    className={style.tableNarrow}
                    labelClassName={style.tableLabelClassName}
                    labelTextClassName={style.tableLabelTextClassName}
                    Parent={(props)=><>{props.children}</>}
                    Tr={(props)=><>{props.children}</>}
                    Th={()=>null}
                    Td={(props)=><TableCell>{props.children}</TableCell>}
                    {...tableProps ? tableProps : {}}
                />
                : null
            }
            <TableCell
                align={"right"}
                onClick={(e)=>e.stopPropagation()}
                className={style.tableItemActions}
            >
                <Menu
                    parentRoute={appContext.routes[name+"Route"]}
                    menu={menu}
                    menuProperties={{user, post}}
                    effect={function ({actions}) {
                        menuActions.actions = actions;
                    }}
                    menuKey={"menu_"+appContext.routes[name+"Route"]+"/"+post._id+"_"+i}
                />
                {(isSelected) ?
                    <IconButton
                        disabled={!!(selectDisabled && selectDisabled({post, selected}))}
                        onClick={(e)=>onSelect(e, post)}
                        classes={{
                            root: materialStyle.listItemIcon,
                            disabled: materialStyle.listItemIconDisabled
                        }}
                    >
                        {(selected) ? <CheckBoxOutlined color={"secondary"}/> : <CheckBoxOutlineBlankOutlined />}
                    </IconButton> : null
                }
            </TableCell>
        </TableRow>
    )

}

function getItemProps(props) {

    const {post, listData, appContext, user, statusManager, name, showAvatars, showThumbnails, type, forceShowSubtitle} = props;

    const N = capitalize(name);

    const author = post?._author?._id || post?._author;
    const isAuthor = (user?._id && user?._id === author);
    const isAdmin = user && user._status_isFeatured;
    const isAuthorOrAdmin = !!(isAuthor || isAdmin);

    const roles = {
        isAuthor,
        isAdmin,
        isAuthorOrAdmin
    };

    const enableStatusOnHeader =
        (listData._status?.show === "header" && !listData._status.role) ||
        (listData._status?.show === "header" && listData._status.role && roles[listData._status.role]);

    const enableStatusOnContent =
        (listData._status?.show === "content" && !listData._status.role) ||
        (listData._status?.show === "content" && listData._status.role && roles[listData._status.role]);

    const enableCreatedDateOnHeader =
        (listData._createdDate?.show === "header" && !listData._createdDate.role) ||
        (listData._createdDate?.show === "header" && listData._createdDate.role && roles[listData._createdDate.role]);

    const enableCreatedDateOnContent =
        (listData._createdDate?.show === "content" && !listData._createdDate.role) ||
        (listData._createdDate?.show === "content" && listData._createdDate.role && roles[listData._createdDate.role]);

    const enableAuthorOnHeader =
        (listData._author?.show === "header" && !listData._author.role) ||
        (listData._author?.show === "header" && listData._author.role && roles[listData._author.role]);

    const enableAuthorOnContent =
        (listData._author?.show === "content" && !listData._author.role) ||
        (listData._author?.show === "content" && listData._author.role && roles[listData._author.role]);

    const enableThumbOnHeader =
        (listData.thumb?.show === "header" && !listData.thumb.role) ||
        (listData.thumb?.show === "header" && listData.thumb.role && roles[listData.thumb.role]);

    const enableSubtitleOnHeader =
        (listData.subtitle?.show === "header" && !listData.subtitle.role) ||
        (listData.subtitle?.show === "header" && listData.subtitle.role && roles[listData.subtitle.role]);

    const enableSubtitleOnContent =
        (listData.subtitle?.show === "content" && !listData.subtitle.role) ||
        (listData.subtitle?.show === "content" && listData.subtitle.role && roles[listData.subtitle.role]);

    const enableTitleOnHeader =
        (listData.title?.show === "header" && !listData.title.role) ||
        (listData.title?.show === "header" && listData.title.role && roles[listData.title.role]);

    const enableTitleOnContent =
        (listData.title?.show === "content" && !listData.title.role) ||
        (listData.title?.show === "content" && listData.title.role && roles[listData.title.role]);

    const enableContentOnContent =
        (listData.content_extract?.show === "content" && !listData.content_extract.role) ||
        (listData.content_extract?.show === "content" && listData.content_extract.role && roles[listData.content_extract.role]);

    const headerStatusText = enableStatusOnHeader && getStatus({user, post, appContext, statusManager, name});
    const headerDateText = enableCreatedDateOnHeader && appContext.labels["date"+N+"Format"]({dateText: post?._createdDate});
    const status = (headerDateText && headerStatusText) ? headerDateText + " ["+headerStatusText+"]" : (headerDateText || headerStatusText);

    const contentStatusText = enableStatusOnContent && getStatus({user, post, appContext, statusManager, name});
    const contentDateText = enableCreatedDateOnContent && appContext.labels["date"+N+"Format"]({dateText: post?._createdDate});
    const contentStatus = (contentDateText && contentStatusText) ? contentDateText + " ["+contentStatusText+"]" : (contentDateText || contentStatusText);

    const tableData = valueToTableData({tableData:props.tableData, post});

    return {
        showThumbnails: showThumbnails && enableThumbOnHeader,
        showAvatars: (type === "card") ? showAvatars && enableAuthorOnHeader : showAvatars && (enableAuthorOnHeader || enableAuthorOnContent),
        showTitles: (type === "card") ? enableTitleOnHeader : (enableTitleOnHeader || enableTitleOnContent),
        showSubtitles: forceShowSubtitle ? forceShowSubtitle : (type === "card") ? enableSubtitleOnHeader : (enableSubtitleOnHeader || enableSubtitleOnContent),
        showContents: enableContentOnContent,
        enableAuthorOnContent,
        enableTitleOnContent,
        enableSubtitleOnContent,
        status: (type === "card") ? status : (status || contentStatus),
        contentStatus: contentStatus,
        tableData
    }

}

function List(props) {

    const {
        posts,
        user,
        statusManager,
        dialog,
        utils,
        name,
        onClick,
        disableAvatars,
        disableThumbnails,
        onSelect,
        isSelected,
        refs,
        Item,
        Parent,
        getMenu,
        getMenuProps = getDefaultMenuProps,
        selectDisabled,
        materialStyle,
        listData,
        type,
        tableProps,
        forceShowSubtitle,
        style,
        getFooterAction
    } = props;

    const context = useContext(WappContext);
    const appContext = useContext(AppContext);

    const {req, wapp} = context;

    const userId = user?._id;
    const postsString = useMemo(()=>posts.filter((post)=>post?._id).map((post)=>post?._id).join(","), [posts]);

    const memoChildren = useMemo(()=> {

        const showThumbnails = !disableThumbnails && posts.find((post)=>post.thumb);
        const showAvatars = !disableAvatars && posts.find((post)=>post._author);

        return [...posts.map(function (post, i){

            const wappRequest = req.wappRequest;
            const {path} = wappRequest;

            const menuActions = {};

            const menuProps = {
                appContext,
                menuActions,
                dialog,
                utils,
                name,
                post,
                statusManager,
                getEditHref: function (p) {
                    return (p?.post?._id) ? "/" + p.post._id + "/edit" : "/";
                },
                ...getMenuProps({
                    appContext,
                    menuActions,
                    dialog,
                    utils,
                    name,
                    post,
                    statusManager,
                    redirects: {
                        onDeleteSuccess: path,
                        onBanSuccess: path,
                        onApproveSuccess: path,
                        onFeaturedSuccess: path,
                        onRemoveFeaturedSuccess: path,
                    }
                }),
            };

            const menu = getMenu ? getMenu({...menuProps}) : getDefaultMenu({...menuProps}).map((m)=>{delete m.featured; return m;});

            function getAuthorObject() {
                return (post?._id && post?._author === post?._id && post?.name) ? post : (post?._author?._id) ? post?._author : null;
            }

            const avatarClick = (e) => {
                e.preventDefault();
                e.stopPropagation();
                const author = getAuthorObject();
                wapp.getTargetObject().history.push({pathname: appContext.routes.userRoute + "/" + author._id, search:"", hash:""})
            };

            const getTitle = () => {
                return post?.title || "No title";
            };

            const itemProps = getItemProps({
                post, listData, appContext, user, statusManager, name, showAvatars, showThumbnails, type, tableData: props.tableData, forceShowSubtitle});

            return (
                <Item {...{
                    key: i,
                    i,
                    refs,
                    style,
                    materialStyle,
                    post,
                    onClick,
                    avatarClick,
                    appContext,
                    menu,
                    user,
                    name,
                    menuActions,
                    isSelected,
                    onSelect,
                    getTitle,
                    selectDisabled,
                    tableProps,
                    getFooterAction,
                    ...itemProps
                }}/>
            )
        })]
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        userId,
        statusManager,
        name,
        dialog,
        onClick,
        utils,
        req.wappRequest,
        wapp,
        isSelected,
        onSelect,
        refs,
        disableThumbnails,
        disableAvatars,
        postsString
    ]);

    return (
        <Parent {...props} >
            {memoChildren}
        </Parent>
    )
}

function Table(props) {

    const appContext = useContext(AppContext);

    const {
        posts,
        listData,
        user,
        statusManager,
        name,
        type,
        disableThumbnails,
        disableAvatars,
        tableProps,
        isSelected,
        selectAll,
        forceShowSubtitle,
        style
    } = props;

    let showThumbnails;
    let showAvatars;
    let showTitles;
    let tableData;

    const enableThumbnails = !disableThumbnails && posts.find((post)=>post.thumb);
    const enableAvatars = !disableAvatars && posts.find((post)=>post._author);

    posts.forEach(function (post){

        if (!showThumbnails || !showAvatars || !showTitles){

            const itemProps = getItemProps({
                post,
                listData,
                appContext,
                user,
                statusManager,
                name,
                showAvatars:enableAvatars,
                showThumbnails:enableThumbnails,
                type,
                tableData: props.tableData,
                forceShowSubtitle
            });

            if (itemProps.showThumbnails && !showThumbnails){
                showThumbnails = itemProps.showThumbnails;
            }

            if (itemProps.showAvatars && !showAvatars){
                showAvatars = itemProps.showAvatars;
            }

            if (itemProps.showTitles && !showTitles){
                showTitles = itemProps.showTitles;
            }

            if (itemProps.tableData && !tableData) {
                tableData = itemProps.tableData;
            }

        }
    });

    function onSelectAll(e){
        selectAll(e);
    }

    return (
        <div className={style.tableContainer}>
            <MaterialTable>
                <TableHead>
                    <TableRow
                        classes={{
                            root: clsx(
                                style.tableItemRoot,
                                materialStyle.tableItem,
                                style.tableItem,
                                {
                                    [style.showThumbnail] : showThumbnails,
                                    [style.showAvatar] : showAvatars,
                                    [style.thereIsAuthor] : posts[0] && posts[0]._author,
                                }
                            ),
                        }}
                    >
                        {
                            (showThumbnails) ?
                                <TableCell align={"left"}>
                                    {appContext.labels[name+"CoverLabel"]}
                                </TableCell>
                                : null
                        }
                        {
                            (showAvatars) ?
                                <TableCell align={"left"}>
                                    {appContext.labels[name+"AuthorLabel"]}
                                </TableCell>
                                : null
                        }
                        {
                            (showTitles) ?
                                <TableCell align={"left"}>
                                    {appContext.labels[name+"TitleLabel"]}
                                </TableCell>
                                : null
                        }
                        {(tableData && Object.keys(tableData).length) ?
                            <TableForItems
                                tableData={tableData}
                                post={posts[0]}
                                user={user}
                                name={name}
                                statusManager={statusManager}
                                className={style.tableNarrow}
                                labelClassName={style.tableLabelClassName}
                                labelTextClassName={style.tableLabelTextClassName}
                                Parent={(props)=><>{props.children}</>}
                                Tr={(props)=><>{props.children}</>}
                                Th={(props)=><TableCell>{props.children}</TableCell>}
                                Td={()=>null}
                                {...tableProps ? tableProps : {}}
                            />
                            : null
                        }
                        <TableCell align={"right"} >
                            {(isSelected) ?
                                <IconButton
                                    color={"primary"}
                                    onClick={(e)=>onSelectAll(e)}
                                    classes={{
                                        root: materialStyle.listItemIcon,
                                        disabled: materialStyle.listItemIconDisabled
                                    }}
                                >
                                    <DoneAllIcon />
                                </IconButton> : null
                            }
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.children}
                </TableBody>
            </MaterialTable>
        </div>
    )
}

function Cards(props) {

    const context = useContext(WappContext);

    const {wapp} = context;

    const {
        style,
        breakPoint = 687,
    } = props;

    const [narrow, _setNarrow] = useState(false);
    const container = useRef();

    useEffect(function didMount(){

        function setNarrow(value) {
            if (narrow !== value) {
                _setNarrow(value)
            }
        }

        function onResize() {
            if (container.current) {
                if (container.current.offsetWidth > breakPoint) {
                    setNarrow(false);
                } else {
                    setNarrow(true);
                }
            } else {
                if (window.innerWidth > breakPoint) {
                    setNarrow(false);
                } else {
                    setNarrow(true);
                }
            }
        }

        function addResizeListeners() {
            if (container.current && typeof ResizeObserver !== "undefined") {
                const resizeObserver = new ResizeObserver((entries) => {
                    onResize(entries);
                });
                resizeObserver.observe(container.current);
                return function removeEventListener(){
                    resizeObserver.disconnect();
                }
            } else {
                window.addEventListener("resize", onResize);
                return function removeEventListener(){
                    window.removeEventListener("resize", onResize);
                }
            }
        }

        const removeResizeListeners = addResizeListeners();

        onResize();

        return function willUnmount() {
            removeResizeListeners();
        }

    }, [narrow, breakPoint, wapp.globals.WAPP]);

    return (
        <div
            ref={container}
            className={clsx(
                style.cardsContainer,
                {[style.narrow]: narrow},
            )}
        >
            {props.children}
        </div>
    )
}

function SelectFunctions(props) {

    const {selectFunctions = [], onChange = (e, func)=>func && func(), appContext, style} = props;
    const [show, setShow] = useState(props.show || false);

    useEffect(()=>{
        if (props.effect){
            props.effect({
                actions: {
                    setShow
                }
            })
        }
        return ()=>{
            if (props.effect){
                props.effect({
                    actions: {
                        setShow: ()=>null
                    }
                })
            }
        }
    }, [props]);

    return (
        <>
            {
                (show && selectFunctions?.length) ?
                    <FormControl
                        className={style.selectFunctions}
                        fullWidth
                    >
                        <Select
                            value={-1}
                            onChange={(e)=>onChange(e, selectFunctions[e.target.value].func)}
                            className={style.select}
                            native={true}
                        >
                            <option key={-1} value={-1} >
                                {appContext.labels.selectAnOperationLabel}
                            </option>
                            {(selectFunctions.map(({label}, i)=>{
                                return (
                                    <option key={i} value={i} >
                                        {label}
                                    </option>
                                )
                            }))}
                        </Select>
                    </FormControl>
                    : null
            }
        </>
    )
}

function Posts(props) {

    const context = useContext(WappContext);
    const appContext = useContext(AppContext);
    const postContext = useContext(PostContext);

    const {wapp, req} = context;

    const {
        subscribe,
        materialStyle,
        name = "post",
        type = "list",
        disableAvatars,
        disableThumbnails,
        disableTable,
        tableProps,
        selectFunctions = [],
        multiple,
        disableSearch,
        searchOnChange,
        sortOnChange,
        perPageOnChange,
        getSearchText = () => req.wappRequest.query.search,
        getSort,
        getPerPage,
        disablePageInfo,
        getMenu,
        getMenuProps,
        thereAreNoEntriesMessage,
        onClickIsSelect,
        effect,
        selectDisabled,
        paginationOnClick,
        getScrollElement,
        SearchFormElement,
        thereAreNoEntriesClick,
        forceShowSubtitle,
        style = defaultStyle,
        getFooterAction,
        beforeSetPosts=({posts})=>posts
    } = props;

    const N = capitalize(name);
    const Ns = (N.endsWith("y")) ? N.slice(0,-1)+"ies" : N+"s";

    const utils = useMemo(()=>getUtils(context), [context]);

    let {
        user,
        post,
        /*parentRoute,*/
        statusManager,
    } = postContext;

    if (!statusManager){
        statusManager = wapp.getTargetObject().postTypes.findPostType({name: name}).statusManager;
    }

    if (!user) {
        user = utils.getRequestUser()
    }

    wapp.styles.use(style);

    const findData = utils.getGlobalState("res.responses."+name+"FindMany") || {};

    const [posts, _setPosts] = useState(beforeSetPosts({posts:props.posts || findData.items || []}));
    const [pageInfo, setPageInfo] = useState(props.pageInfo || findData.pageInfo || {});

    async function setPosts(posts) {
        await _setPosts(beforeSetPosts({posts}))
    }

    const selectable = (typeof props.selectable === "function") ? props.selectable({posts}) : props.selectable;

    const refs = useMemo(()=>{return {}}, []);
    const selected = useMemo(()=>{return {value: props.selected || [],}}, [props.selected]);
    const setSelected = useMemo(()=>(value = selected.value)=>{selected.value = value}, [selected]);
    const isSelected = useMemo(()=>(value)=>selected.value.indexOf(value) > -1, [selected.value]);

    const scrollToTop = useMemo(()=>()=>{
        const scrollElement = (getScrollElement) ? getScrollElement() : null;
        if (scrollElement) {
            scrollElement.scrollTop = 0;
        }
    }, [getScrollElement]);

    const onRequestResolved = useMemo(()=>async function onRequestResolved({value}) {

        const requestName = name + "FindMany";
        const response = value;

        let newPageInfo = null;
        let foundData = null;

        if (response && response[requestName]) {
            foundData = response[requestName] || {};
            newPageInfo = foundData.pageInfo || {};
        }

        if (foundData && newPageInfo) {

            const samePageDifferentPostsUpdate = !!(
                newPageInfo.currentPage === pageInfo.currentPage &&
                newPageInfo.sort === pageInfo.sort &&
                newPageInfo.perPage === pageInfo.perPage &&
                foundData.items && JSON.stringify(foundData.items) !== JSON.stringify(posts)
            );

            if (samePageDifferentPostsUpdate) {

                const items = foundData?.items || [];
                await setPosts([...items]);
                await setPageInfo({...newPageInfo});

                scrollToTop();

            }

        }

    }, [name, pageInfo.currentPage, pageInfo.perPage, pageInfo.sort, posts, scrollToTop]);

    useEffect(()=>{
        if (effect){
            effect({
                actions: {
                    getSelectedPosts: (all)=>(all) ? selected.value.map((id)=>posts.find((post)=>post._id === id) || {_id:id}) : selected.value.map((id)=>posts.find((post)=>post._id === id)).filter((post)=>post && post._id),
                    getSelectedIds: (all)=>(all) ? [...selected.value] : selected.value.map((id)=>posts.find((post)=>post._id === id)).filter((post)=>post && post._id),
                    setPosts,
                    setPageInfo,
                    scrollToTop
                }
            })
        }
        return ()=> {
            if (effect){
                effect({
                    actions: {
                        getSelectedPosts: ()=>null,
                        getSelectedPostsIds: ()=>null,
                        setPosts: ()=>null,
                        setPageInfo: ()=>null,
                        scrollToTop: ()=>null
                    }
                })
            }
        }
    }, [effect, posts, scrollToTop, selected]);

    useEffect(function (){
        const unsub = subscribe.requestResolved(onRequestResolved);
        return function useUnsubscribe(){
            unsub();
        }
    }, [onRequestResolved, post, subscribe]);

    const dialog = useMemo(()=>{
        return {
            actions: {}
        };
    }, []);

    const dialogEffect = useMemo(()=>function ({actions}) {
        dialog.actions = actions;
    }, [dialog]);

    const selectFunctionsRef = useMemo(()=>{
        return {
            actions: {}
        };
    }, []);

    const selectFunctionsEffect = useMemo(()=>function ({actions}) {
        selectFunctionsRef.actions = actions;
    }, [selectFunctionsRef]);

    const onSelect = useMemo(()=>async function (e, post) {
        if (selectDisabled && selectDisabled({post, selected: (selected.value.indexOf(post._id) > -1)})){
            return;
        }

        e.preventDefault();

        const id = post._id;
        const value = selected.value;

        const nS = (value.indexOf(id) === -1) ?
            value.reduce((nS, selectedId, i)=>{nS.push(selectedId); if (i === value.length-1){nS.push(id)} return nS}, (value.length) ? [] : [id]).slice((multiple) ? 0 : -1) :
            value.filter((selectedId)=>selectedId !== id).slice((multiple) ? 0 : -1);

        const shouldDeselect = value.filter(x => !nS.includes(x));

        shouldDeselect.forEach((deselectId)=>{
            if (deselectId !== id && refs[deselectId]){
                refs[deselectId].deselect();
            }
        });

        if (props.onSelect){
            const selected = {
                value: nS
            };
            await props.onSelect(e, {
                getSelectedPosts: (all)=>(all) ? selected.value.map((id)=>posts.find((post)=>post._id === id) || {_id:id}) : selected.value.map((id)=>posts.find((post)=>post._id === id)).filter((post)=>post && post._id),
                getSelectedIds: (all)=>(all) ? [...selected.value] : selected.value.map((id)=>posts.find((post)=>post._id === id)).filter((post)=>post && post._id),
                setPosts,
                setPageInfo,
                scrollToTop
            })
        } else {
            await setSelected(nS);
            await selectFunctionsRef.actions.setShow(nS.length);
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.onSelect, selectDisabled, selected.value, multiple, setSelected, selectFunctionsRef.actions, refs]);

    const selectAll = useMemo(()=>async (e)=>{
        e.preventDefault();
        return await Promise.all(posts.map(async (post)=>{
            if (selectDisabled && selectDisabled({post, selected: (selected.value.indexOf(post._id) > -1)})){
                return;
            }
            if (!(selected.value.indexOf(post._id) > -1)) {
                refs[post._id].select();
                return await onSelect(e, post);
            }
        }))
    }, [posts, onSelect]);

    const deselectAll = useMemo(()=>async (e)=>{
        e.preventDefault();
        return await Promise.all(posts.map(async (post)=>{
            if ((selected.value.indexOf(post._id) > -1)) {
                refs[post._id].deselect();
                return await onSelect(e, post);
            }
        }))
    }, [posts, onSelect]);

    const onClick = useMemo(()=>async function (e, post) {

        if (onClickIsSelect){

            const id = post._id;

            if (selectDisabled && selectDisabled({post, selected: (selected.value.indexOf(id) > -1)})){
                return;
            }

            if (refs[id]) {
                refs[id].toggle();
            }
            await onSelect(e, post);

        } else {

            if (props.onClick){

                props.onClick(e, post);

            } else {

                e.preventDefault();

                wapp.getTargetObject().history.push({
                    search:"",
                    hash:"",
                    ...wapp.getTargetObject().history.parsePath(appContext.routes[name+"Route"]+"/"+post._id)
                });

            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onClickIsSelect, selectDisabled, selected.value, refs, onSelect, props.onClick, wapp, appContext.routes, name]);

    const listDataObject = utils.getGlobalState("res.graphql.query."+name+"FindMany.listData");
    const listData = props.listData || listDataObject.list;
    const tableData = props.tableData || listDataObject.table;

    const listProps = {
        Parent: (type === "list") ?
            (props)=><MaterialList dense={true} >{props.children}</MaterialList> :
            (type === "table") ? Table : Cards,
        Item: (type === "list") ? ListItem : (type === "table") ? TableItem : CardItem,
        refs,
        style,
        posts,
        user,
        statusManager,
        dialog,
        utils,
        name,
        onClick,
        disableAvatars,
        disableThumbnails,
        onSelect: onSelect,
        isSelected: (selectable) ? isSelected : null,
        getMenu,
        getMenuProps,
        onClickIsSelect,
        selectDisabled,
        materialStyle,
        listData,
        type,
        tableData: (!disableTable) ? tableData : null,
        tableProps,
        selectAll: async (e)=>selected?.value?.length === posts?.length ? await deselectAll(e) : await selectAll(e),
        forceShowSubtitle,
        getFooterAction
    };

    const filteredSelectFunctions = selectFunctions.filter(({role})=>{
        return !role ? true : role({posts});
    });

    return (
        <div className={
            clsx(
                materialStyle.root,
                style.posts,
                {
                    [style.tableType] : (type === "table"),
                    [style.cardType] : (type === "card"),
                }
            )
        }>
            {(posts?.length) ?
                <div className={style.page}>
                    {(!disablePageInfo) ?
                        <ListTools
                            data={pageInfo}
                            disableSearch={disableSearch}
                            searchOnChange={searchOnChange}
                            sortOnChange={sortOnChange}
                            perPageOnChange={perPageOnChange}
                            searchText={getSearchText()}
                            {...(getSort && getSort()) ? {sort: getSort()} : {}}
                            {...(getPerPage && getPerPage()) ? {perPage: getPerPage()} : {}}
                            name={name}
                            SearchFormElement={SearchFormElement}
                        /> : null}
                    <SelectFunctions
                        show={(selectable && selected.value.length && filteredSelectFunctions.length)}
                        effect={selectFunctionsEffect}
                        onChange={(e, func)=>func && func(e, {dialog, selected, posts, setPosts, deselectAll})}
                        selectFunctions={filteredSelectFunctions}
                        appContext={appContext}
                        style={style}
                    />
                    <List {...listProps}/>
                    <Pagination data={pageInfo} onClick={paginationOnClick} />
                    <Dialog effect={dialogEffect} />
                </div>
                :
                <div className={style.page}>
                    {(getSearchText() && !disablePageInfo) ?
                        <ListTools
                            data={pageInfo}
                            disableSearch={disableSearch}
                            searchOnChange={searchOnChange}
                            sortOnChange={sortOnChange}
                            perPageOnChange={perPageOnChange}
                            searchText={getSearchText()}
                            {...(getSort && getSort()) ? {sort: getSort()} : {}}
                            {...(getPerPage && getPerPage()) ? {perPage: getPerPage()} : {}}
                            name={name}
                            SearchFormElement={SearchFormElement}
                        /> : null}
                    <div
                        className={clsx(
                            style.thereAreNoEntries,
                            {[style.clickable]: thereAreNoEntriesClick}
                        )}
                        onClick={thereAreNoEntriesClick}
                    >
                        <Typography variant={"subtitle1"}>
                            {thereAreNoEntriesMessage || appContext.messages["thereAreNo"+Ns] || appContext.messages.thereAreNoEntries}
                        </Typography>
                    </div>
                    {(pageInfo?.hasPreviousPage) ? <Pagination data={pageInfo} onClick={paginationOnClick} /> : null}
                </div>
            }
        </div>
    )
}

const WappComponent = withWapp(Posts);

const StyledComponent = withMaterialStyles(materialStyle, WappComponent);

export default StyledComponent;
