export default async function (appSlug) {

    let createChildWapp
    let defaultAppData
    let schema
    let getGenerateFunctions
    let getRandomFunctions
    let defaultDimensions

    switch(appSlug) {
        case "the-vase-and-the-finial":
            createChildWapp = (await import("the-vase-and-the-finial")).createChildWapp
            defaultAppData = (await import("the-vase-and-the-finial/dist/common/defaultAppData")).default
            schema = (await import("the-vase-and-the-finial/dist/common/schema")).default
            getGenerateFunctions = null;
            getRandomFunctions = null;
            defaultDimensions = null;
            break;
        case "hatchet-on-the-table":
            createChildWapp = (await import("hatchet-on-the-table")).createChildWapp
            defaultAppData = (await import("hatchet-on-the-table/dist/common/defaultAppData")).default
            schema = (await import("hatchet-on-the-table/dist/common/schema")).default
            getGenerateFunctions = null;
            getRandomFunctions = null;
            defaultDimensions = null;
            break;
        case "atonement-the-victim":
            createChildWapp = (await import("atonement-the-victim")).createChildWapp
            defaultAppData = (await import("atonement-the-victim/dist/common/defaultAppData")).default
            schema = (await import("atonement-the-victim/dist/common/schema")).default
            getGenerateFunctions = null;
            getRandomFunctions = null;
            defaultDimensions = null;
            break;
        case "the-constructions-app":
            createChildWapp = (await import("the-constructions-app")).createChildWapp
            defaultAppData = (await import("the-constructions-app/dist/common/defaultAppData")).default
            schema = (await import("the-constructions-app/dist/common/schema")).default
            getGenerateFunctions = (await import("the-constructions-app/dist/common/components/Canvas/getUtils/getGenerateFunctions")).default;
            getRandomFunctions = (await import("the-constructions-app/dist/common/components/Canvas/getUtils/getRandomFunctions")).default;
            defaultDimensions = (await import("the-constructions-app/dist/common/components/Canvas/getUtils/getDefaults")).defaultDimensions;
            break;
        case "binary-words":
            createChildWapp = (await import("binary-words")).createChildWapp
            defaultAppData = (await import("binary-words/dist/common/defaultAppData")).default
            schema = (await import("binary-words/dist/common/schema")).default
            getGenerateFunctions = null;
            getRandomFunctions = null;
            defaultDimensions = null;
            break;
        default:
            createChildWapp = (await import("default-app")).default
            defaultAppData = (await import("default-app/dist/common/defaultAppData")).default
            schema = (await import("default-app/dist/common/schema")).default
            getGenerateFunctions = null;
            getRandomFunctions = null;
            defaultDimensions = (await import("default-app/dist/common/defaultAppData")).defaultDimensions
    }

    return {
        createChildWapp,
        defaultAppData,
        schema,
        getGenerateFunctions,
        getRandomFunctions,
        defaultDimensions
    }
}
