import React, {useContext, useEffect, useState} from "react";

import {WappContext, withWapp} from "wapplr-react/dist/common/Wapp";
import getUtils from "wapplr-react/dist/common/Wapp/getUtils";

import Menu from "../Menu";
import AppContext from "../App/context";
import {withMaterialStyles} from "../Template/withMaterial";

import Container from "@mui/material/Container";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import AppBar from "@mui/material/AppBar";
import Paper from "@mui/material/Paper";
import Chip from "@mui/material/Chip";

import getStatus from "../../utils/getStatus";
import capitalize from "../../utils/capitalize";

import Dialog from "../Dialog";
import NotFound from "../NotFound";
import Avatar from "../Avatar";
import PostContext from "../Post/context";

import style from "./style.css";
import materialStyle from "./materialStyle";

import getDefaultMenu, {getMenuProps} from "./menu";
import {defaultGetPageName} from "./utils";

import Router from "./Router";
import New from "./New";
import Edit from "./Edit";
import Content from "./Content";
import clsx from "clsx";
import IconButton from "@mui/material/IconButton";
import BackIcon from "@mui/icons-material/ArrowBack";

const defaultPages = {
    content: Content,
    new: New,
    edit: Edit,
};

function Post(props) {

    const appContext = useContext(AppContext);
    const context = useContext(WappContext);

    const {userStatusManager, routes, titles} = appContext;

    const {subscribe, materialStyle, ...rest} = props;

    const utils = getUtils(context);
    const {wapp, res} = context;

    const {
        name = "post",
        getTitle = function ({post}) {return post?.title},
        getSubtitle = function ({post}) {return post?.subtitle},
        getMenu,
        menuProperties,
        layoutType,
        getTopMenu,
        maxWidth = "md",
        getPageName = defaultGetPageName,
        getDashboardTitle = function (/*{post}*/) {return titles.dashboardTitle},
        contentClassName,
        requestKeys = [name+"FindById"],
        getInitialResponse = function () {
            return requestKeys.map((requestName)=>utils.getGlobalState("res.responses."+requestName)).find((r)=>r?._id);
        },
        onBackClick,
        disableBack,
        pageProps,
        showAuthor=()=>true,
        showCreatedDate=()=>true,
    } = rest;

    const N = capitalize(name);

    const pages = {
        ...defaultPages,
        ...(props.pages) ? props.pages : {}
    };

    const {parentRoute = routes[name+"Route"]} = rest;

    const statusManager = wapp.getTargetObject().postTypes.findPostType({name: name}).statusManager;

    wapp.styles.use(style);

    const page = (res.wappResponse.route.path.startsWith(routes[name+"Route"]+"/new")) ? "new" : res.wappResponse.route.params.page;

    const [user, setUser] = useState(utils.getRequestUser());
    const [post, setPost] = useState((page === "new") ? null : props.post || getInitialResponse());

    function onUserChange(user){
        setUser((user?._id) ? user : null);
    }

    function onRequestResolved({value}) {
        const keys = [...requestKeys];
        const response = value;
        let foundEnabledKeys = false;
        keys.forEach(function (requestName) {
            if (!foundEnabledKeys && response && response[requestName]) {
                if ((post?._id && response[requestName]._id === post._id) || (!post?._id && response[requestName]._id)) {
                    foundEnabledKeys = true;
                    const needUpdate = !post?._id || (post?._id && JSON.stringify(response[requestName]) !== JSON.stringify(post));
                    if (needUpdate) {
                        const newPost = {...response[requestName]};
                        setPost(newPost);
                    }
                }
            }
        })
    }

    useEffect(function (){
        const unsub = subscribe.userChange(onUserChange);
        return function useUnsubscribe(){
            unsub();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    useEffect(function (){
        const unsub = subscribe.requestResolved(onRequestResolved);
        return function useUnsubscribe(){
            unsub();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [post]);

    useEffect(function (){
        if (page === "new"){
            setPost(null);
        } else {
            setPost(post || getInitialResponse())
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page]);

    function getTitleByPageName() {

        const pageName = getPageName({user, post, page, statusManager, userStatusManager});

        if (pageName === "new") {
            return titles["new"+capitalize(name)+"Title"];
        }

        if (pageName === "edit") {
            return titles["edit"+capitalize(name)+"Title"];
        }

        if (pageName === "content") {
            return getTitle({user, post, page}) || titles[name+"Title"];
        }

        return getTitle({user, post, page}) || titles[name+"Title"] || "";

    }

    const subtitle = (page === "edit") ? null : getSubtitle({user, post, page});

    const dialog = {
        actions: {}
    };

    const dialogEffect = function ({actions}) {
        dialog.actions = actions;
    };

    let menuActions = {};

    const menuProps = getMenuProps({appContext, menuActions, dialog, utils, name, post, parentRoute});

    const menu = (getMenu) ? getMenu({...menuProps, statusManager}) : getDefaultMenu({...menuProps, statusManager});

    const topMenu = (getTopMenu) ? getTopMenu({...menuProps, statusManager}) : [];

    const pageName = getPageName({user, post, page, statusManager, userStatusManager});

    if (!pageName || pageName === "notFound"){
        res.wappResponse.status(404)
    }

    const avatarClick = (e) => {
        e.preventDefault();
        const author = getAuthorObject();
        wapp.client.history.push({pathname: routes.userRoute + "/" + author._id, search:"", hash:""})
    };

    function getAuthorObject() {
        return (post?._id && post?._author === post?._id && post?.name) ? post : (post?._author?._id) ? post?._author : null;
    }

    function getAuthorName({justFirst}) {
        const author = getAuthorObject();
        if (justFirst){
            return author?.name?.first || "User";
        }
        return author.title || "User";
    }

    function backClick(e) {
        e.preventDefault();
        if (post?._id) {
            wapp.client.history.push({pathname: routes[name + "Route"] + "/" + post._id, search: "", hash: ""});
        }
    }

    function userClick(e) {
        e.preventDefault();
        if (user?._id) {
            wapp.client.history.push({pathname: routes.userRoute + "/" + user._id, search: "", hash: ""});
        }
    }

    const status = post?._id && getStatus({user, post, page, appContext, statusManager, name});
    const dateText = (showCreatedDate({user, post, author: getAuthorObject()})) ? appContext.labels["date"+N+"Format"]({dateText: post?._createdDate}) : "";
    const thirdRow = (dateText && status) ? dateText + " ["+status+"]" : (dateText || status);

    return (
        <>
            {
                (pageName && pageName !== "notFound") ?
                    <div className={clsx(
                        style.post,
                        {[style[layoutType]]: layoutType && style[layoutType]}
                    )}>
                        <Container fixed className={style.container} maxWidth={maxWidth}>
                            <Paper>
                                {(layoutType === "user") ?
                                    <div className={style.userLayout}>
                                        {
                                            (topMenu.length) ?
                                                <div className={style.topMenu}>
                                                    <Menu
                                                        parentRoute={parentRoute}
                                                        menu={topMenu}
                                                        materialStyle={materialStyle}
                                                        menuProperties={{user, post, page, ...menuProperties}}
                                                    />
                                                </div>
                                                :
                                                null
                                        }
                                        <div className={style.userBox}>
                                            <div className={style.avatarContainer} onClick={avatarClick}>
                                                <Avatar user={getAuthorObject()} size={"big"} color={"secondary"}/>
                                            </div>
                                            <div
                                                className={clsx(
                                                    style.titleContainer,
                                                    {[style.withSubtitle]: subtitle}
                                                )}
                                            >
                                                <div className={style.title}>
                                                    <Typography variant={"h5"} className={style.title}>
                                                        {getTitleByPageName()}
                                                    </Typography>
                                                </div>
                                                {(subtitle) ?
                                                    <div className={style.subtitle}>
                                                        <Typography variant={"subtitle1"} color={"textSecondary"} className={style.subtitle}>
                                                            {subtitle}
                                                        </Typography>
                                                    </div>
                                                    :
                                                    null
                                                }
                                                {(page !== "new" && getStatus({user, post, appContext, statusManager, name})) ?
                                                    <div className={style.status}>
                                                        <Typography variant={"subtitle1"} color={"textSecondary"} className={style.subtitle}>
                                                            {getStatus({user, post, appContext, statusManager, name})}
                                                        </Typography>
                                                    </div>
                                                    :
                                                    null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    : null
                                }
                                <AppBar position={"relative"}
                                        className={style.appBar}
                                >
                                    <Toolbar
                                        className={style.toolBar}
                                    >
                                        {
                                            (layoutType !== "user") ?
                                                <>
                                                    {((page && !disableBack) || (onBackClick && !disableBack)) ?
                                                        <div className={style.backButtonContainer}>
                                                            <IconButton
                                                                color={"inherit"}
                                                                onClick={(onBackClick) ? onBackClick : (page === "new") ? userClick : backClick}
                                                            >
                                                                <BackIcon />
                                                            </IconButton>
                                                        </div>
                                                        : null
                                                    }
                                                    {(layoutType !== "article") ?
                                                        <>
                                                            {(showAuthor({user, post, author: getAuthorObject()}) && getAuthorObject()) ?
                                                                <div className={style.avatarContainer}
                                                                     onClick={avatarClick}>
                                                                    <Avatar user={getAuthorObject()}/>
                                                                </div>
                                                                : null
                                                            }
                                                            <div
                                                                className={clsx(
                                                                    style.titleContainer,
                                                                    {
                                                                        [style.withSubtitle]: subtitle,
                                                                        [style.withStatus]: (page !== "new" && thirdRow)
                                                                    }
                                                                )}
                                                            >
                                                                <div className={style.title}>
                                                                    <Typography variant={"h6"} className={style.title}>
                                                                        {getTitleByPageName()}
                                                                    </Typography>
                                                                </div>
                                                                {(subtitle) ?
                                                                    <div className={style.subtitle}>
                                                                        <Typography variant={"subtitle1"}
                                                                                    color={"textSecondary"}
                                                                                    className={style.subtitle}>
                                                                            {subtitle}
                                                                        </Typography>
                                                                    </div>
                                                                    :
                                                                    null
                                                                }
                                                                {(page !== "new" && thirdRow) ?
                                                                    <div className={style.status}>
                                                                        <Typography variant={"subtitle1"}
                                                                                    color={"textSecondary"}
                                                                                    className={style.subtitle}>
                                                                            {thirdRow}
                                                                        </Typography>
                                                                    </div>
                                                                    :
                                                                    null
                                                                }
                                                            </div>
                                                        </>
                                                        :
                                                        <div style={{width: "100%"}}/>
                                                    }
                                                </>
                                                :
                                                <>
                                                    {(page || onBackClick) ?
                                                        <div className={style.backButtonContainer}>
                                                            <IconButton
                                                                color={"inherit"}
                                                                onClick={onBackClick ? onBackClick : avatarClick}
                                                            >
                                                                <BackIcon />
                                                            </IconButton>
                                                        </div>
                                                        : null
                                                    }
                                                    <div className={style.titleContainer} >
                                                        <Typography variant={"h6"} className={style.title}>
                                                            {getDashboardTitle({user, post, page})}
                                                        </Typography>
                                                    </div>
                                                </>
                                        }
                                        <Menu
                                            parentRoute={parentRoute}
                                            menu={menu}
                                            materialStyle={materialStyle}
                                            menuProperties={{user, post, page, ...menuProperties}}
                                            effect={function ({actions}) {
                                                menuActions.actions = actions;
                                            }}
                                        />
                                    </Toolbar>
                                </AppBar>
                                <PostContext.Provider value={{name, user, post, parentRoute, statusManager, page}}>
                                    <div className={clsx(
                                        style.content,
                                        {[style[contentClassName]]: contentClassName && style[contentClassName]}
                                    )}>
                                        {(layoutType === "article") ?
                                            <div
                                                className={clsx(
                                                    style.titleContainer,
                                                    {
                                                        [style.withSubtitle]: subtitle,
                                                        [style.withStatus]: (page !== "new" && thirdRow)
                                                    }
                                                )}
                                            >
                                                <div className={style.title}>
                                                    <Typography variant={"h6"} className={style.title}>
                                                        {getTitleByPageName()}
                                                    </Typography>
                                                </div>
                                                {(subtitle) ?
                                                    <div className={style.subtitle}>
                                                        <Typography variant={"subtitle1"} color={"textSecondary"} className={style.subtitle}>
                                                            {subtitle}
                                                        </Typography>
                                                    </div>
                                                    :
                                                    null
                                                }
                                                {(page !== "new" && thirdRow) ?
                                                    <div className={style.status}>
                                                        <Typography variant={"subtitle1"} color={"textSecondary"} className={style.subtitle}>
                                                            {thirdRow}
                                                        </Typography>
                                                    </div>
                                                    :
                                                    null
                                                }
                                                {(showAuthor({user, post, author: getAuthorObject()}) && getAuthorObject()) ?
                                                    <div className={style.avatarContainer} onClick={avatarClick}>
                                                        <Chip
                                                            avatar={<Avatar user={getAuthorObject()} />}
                                                            label={getAuthorName({justFirst: true})}
                                                            variant={"outlined"}
                                                            color={"secondary"}
                                                        />
                                                    </div>
                                                    : null
                                                }
                                            </div> : null
                                        }
                                        <Router page={page} pages={pages} router={getPageName} pageProps={pageProps}/>
                                    </div>
                                    <Dialog effect={dialogEffect} />
                                </PostContext.Provider>
                            </Paper>
                        </Container>
                    </div>
                    :
                    <NotFound />
            }
        </>
    )
}

const WappComponent = withWapp(Post);

const StyledComponent = withMaterialStyles(materialStyle, WappComponent);

export default StyledComponent;
