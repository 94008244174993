import React from "react";

import getUtils from "wapplr-react/dist/common/Wapp/getUtils";

import ArticleIcon from "@mui/icons-material/Article";
import DeleteIcon from "@mui/icons-material/Delete";
import StarIcon from "@mui/icons-material/Star";
import MoodBadIcon from "@mui/icons-material/MoodBad";
import BlockIcon from "@mui/icons-material/Block";
import ShortTextIcon from "@mui/icons-material/ShortText";
import ArticleOutlined from "@mui/icons-material/ArticleOutlined";
import AddIcon from "@mui/icons-material/Add";

import App from "../../components/App";
import capitalize from "../../utils/capitalize";
import collectRouteParams from "../../utils/collectRouteParams";

import Posts from "../../components/Posts";

import {runPostTypesConfig} from "../index";

export function getTitleFunction({name = "post", titles, routes, getTitle = function ({title}) {return title;}}) {

    return function (p = {}) {
        const n = p.name || name;
        const N = capitalize(n);
        const ns = (n.endsWith("y")) ? n.slice(0,-1)+"ies" : n+"s";
        const Ns = (N.endsWith("y")) ? N.slice(0,-1)+"ies" : N+"s";

        const wappResponse = p.res.wappResponse;
        const post = wappResponse.store.getState("res.responses."+n+"FindById");
        const route = wappResponse.route;
        const {path, params} = route;

        const page = params.page ? params.page : (params.pagination && isNaN(Number(params.pagination))) ? params.pagination : "";

        let title =
            (path.startsWith(routes[ns+"Route"])) ?
                (page === "banned") ?
                    titles["banned"+Ns+"Title"] :
                    (page === "deleted") ?
                        titles["deleted"+Ns+"Title"] :
                        (page === "missingdata") ?
                            titles["missingData"+Ns+"Title"] :
                            (page === "protected") ?
                                titles["protected"+Ns+"Title"] :
                                (page === "lowauthorstatus") ?
                                    titles["lowAuthorStatus"+Ns+"Title"] :
                                    titles[ns+"Title"] :
                (path === routes[n+"Route"]+"/new") ?
                    titles["new"+N+"Title"] :
                    titles[n+"Title"];

        if (post && params._id === post._id && post.title) {
            title = (page === "edit") ? titles["edit"+N+"Title"] + " | " + post.title : post.title;
        }
        return getTitle({...p, title})
    }
}
export function getDescriptionFunction({name = "post", getTitle}) {
    return function (p) {
        const {wapp, res} = p;
        const wappResponse = res.wappResponse;
        const post = wappResponse.store.getState("res.responses."+name+"FindById");
        if (post?._id && post?.content_extract){
            return post.content_extract;
        }
        const config = wapp.getTargetObject().config;
        const {description} = config;
        return (description) ? description : getTitle(p).split(" | ")[0];
    }
}

export function getDefaultContent(p = {}) {

    const {name, titles, routes, getTitle} = p;

    const Render = p.App || App;

    const N = capitalize(name);

    const titleFunction = getTitleFunction({name, titles, routes, getTitle});

    let reqUserForPost = null;

    return {
        render: Render,
        renderType: "react",
        title: titleFunction,
        description: getDescriptionFunction({name, getTitle: titleFunction}),
        request: async function ({wapp, req, res}) {
            const wappResponse = res.wappResponse;
            const post = wappResponse.store.getState("res.responses." + name + "FindById");
            const route = wappResponse.route;
            const {params} = route;
            const {_id} = params;

            const statusManager = wapp.getTargetObject().postTypes.findPostType({name}).statusManager;

            await runPostTypesConfig({action: "requestFor" + N + "Page", p: {wapp, req, res, statusManager}});

            if ((_id && !post) ||
                (_id && post && post._id !== _id) ||
                (_id && reqUserForPost?._id !== req.wappRequest.user?._id)) {
                reqUserForPost = {_id: req.wappRequest.user?._id};
                return await wapp.requests.send({requestName: name + "FindById", args: {_id: _id}, req, res});
            }
        }
    }
}

export function getListContent(p = {}) {

    const {name, titles, routes, getTitle, urlParams} = p;
    const Render = p.App || App;

    const titleFunction = getTitleFunction({name, titles, routes, getTitle});

    return {
        render: Render,
        renderType: "react",
        title: titleFunction,
        description: getDescriptionFunction({name, getTitle: titleFunction}),
        request: async function ({wapp, req, res}) {

            const wappResponse = res.wappResponse;
            const wappRequest = req.wappRequest;
            const route = wappResponse.route;
            const {params} = route;
            const query = wappRequest.query;

            const postStatusManager = wapp.getTargetObject().postTypes.findPostType({name}).statusManager;
            const userStatusManager = wapp.getTargetObject().postTypes.findPostType({name: "user"}).statusManager;

            const listData = wappResponse.store.getState("res.graphql.query."+name+"FindMany.listData") || {};
            const listDataSort = listData.sort || [];

            if (query.search) {
                listDataSort.push({key: "TEXTSCORE"})
            }

            const defaultSort = listDataSort[0]?.key || "";

            let sort = (params.sort && listDataSort && listDataSort.map((p)=>p.key).find((key)=>key === params.sort)) || defaultSort;

            if (sort === "TEXTSCORE") {
                sort = "";
            }

            const page = params.page;
            const pagination = !isNaN(Number(params.pagination)) && Number(params.pagination) ? Number(params.pagination) : 1;

            const perPageFormData = listData.perPage;
            const limitPerPage = perPageFormData.limit || 100;
            const defaultPerPage = perPageFormData.default || 20;
            const perPage = (params.limit && !isNaN(Number(params.limit)) && Number(params.limit) <= limitPerPage && Number(params.limit) > 1) ? Number(params.limit) : defaultPerPage;

            const formData = wappResponse.store.getState("res.graphql.query."+name+"FindMany.formData");

            const filter = urlParams.reduce((o, urlParamText)=>{
                if (!urlParamText.startsWith("/page/:") && !urlParamText.startsWith("/sort/:") && !urlParamText.startsWith("/limit/:")){
                    const paramNameN = urlParamText.startsWith("/") ? 1 : 0;
                    const paramName = urlParamText.split("/")[paramNameN];
                    if (params[paramName] && formData["filter."+paramName]){
                        const {schemaType, multiple} = formData["filter."+paramName];
                        let value = params[paramName];
                        if (multiple){
                            try {
                                value = (value.startsWith("[") && value.endsWith("]")) ? JSON.parse(value) : value.split(",");
                            } catch (e){}
                        } else {
                            if (schemaType === "Float" || schemaType === "Int"){
                                value = Number(value);
                            }
                        }
                        if (typeof value !== "undefined"){
                            o[paramName] = value;
                        }
                    }
                }
                return o;
            }, {});

            const authorStatus =
                (page === "lowauthorstatus") ?
                    {
                        _author_status: {
                            lt: userStatusManager.getMinStatus()
                        }
                    } :
                    (page === "banned" || page === "deleted" || page === "missingdata" || page === "protected") ? {
                            _author_status: {
                                gt: userStatusManager.getBannedStatus() - 1
                            }
                        } :
                        {
                            _author_status: {
                                gt: userStatusManager.getMinStatus() - 1
                            }
                        };

            const status =
                (page === "banned") ?
                    {gt: postStatusManager.getBannedStatus() - 1, lt: postStatusManager.getDeletedStatus()} :
                    (page === "deleted") ?
                        {gt: postStatusManager.getDeletedStatus() - 1, lt: postStatusManager.getDefaultStatus()} :
                        (page === "missingdata") ?
                            {gt: postStatusManager.getDefaultStatus() - 1, lt: postStatusManager.getMinStatus()} :
                            (page === "protected") ?
                                {gt: postStatusManager.getFeaturedStatus() - 1} :
                                (page === "lowauthorstatus") ?
                                    {gt: postStatusManager.getBannedStatus() - 1} :
                                    {gt: postStatusManager.getMinStatus() - 1};

            return await wapp.requests.send({
                requestName: name+"FindMany",
                args: {
                    filter: {
                        ...(query.search) ? {search: query.search} : {},
                        ...(Object.keys(filter).length) ? filter : {},
                        _operators:{
                            _status: status,
                            ...authorStatus
                        }
                    },
                    ...(sort) ? {sort} : {},
                    page: pagination,
                    perPage
                },
                req,
                res
            });

        }
    }
}

export default function setContents(p = {}) {

    const {
        wapp,
        name = "post",
        routes,
        urlParams = [
            "/page/:pagination",
            "/sort/:sort",
            "/limit/:limit"
        ],
        ...rest
    } = p;

    const n = name;
    const ns = (n.endsWith("y")) ? n.slice(0,-1)+"ies" : n+"s";

    wapp.contents.add({
        [n]: getDefaultContent({wapp, name, routes, urlParams, App, ...rest}),
        [ns]: getListContent({wapp, name, routes, urlParams, App, ...rest})
    });

    const paths = collectRouteParams({
        startRoutes: [
            {path: routes[ns+"Route"], contentName: ns},
            {path: routes[ns+"Route"]+"/:page", contentName: ns},
        ],
        params: urlParams
    });

    wapp.router.add([
        {path: routes[n+"Route"], contentName: n},
        {path: routes[n+"Route"]+"/new", contentName: n},
        {path: routes[n+"Route"]+"/:_id", contentName: n},
        {path: routes[n+"Route"]+"/:_id/:page", contentName: n},

        ...paths

    ]);

    return {
        name: n
    }

}

export async function requestForUserPage({wapp, req, res, statusManager, name="post"}) {

    const ns = (name.endsWith("y")) ? name.slice(0,-1)+"ies" : name+"s";

    const postStatusManager = wapp.getTargetObject().postTypes.findPostType({name}).statusManager;

    const wappResponse = res.wappResponse;
    const wappRequest = req.wappRequest;
    const user = wappRequest.user;
    const isAdmin = user && user._status_isFeatured;
    const route = wappResponse.route;
    const {params} = route;
    const {_id, page, pageType} = params;
    const query = wappRequest.query;

    const isAuthor = ((user?._id && user?._id === _id));

    const listData = wappResponse.store.getState("res.graphql.query."+name+"FindMany.listData") || {};
    const listDataSort = listData.sort || [];

    if (query.search) {
        listDataSort.push({key: "TEXTSCORE"})
    }

    const defaultSort = listDataSort[0]?.key || "";

    let sort = (params.sort && listDataSort && listDataSort.map((p)=>p.key).find((key)=>key === params.sort)) || defaultSort;

    if (sort === "TEXTSCORE") {
        sort = "";
    }

    const pagination = !isNaN(Number(params.pagination)) && Number(params.pagination) ? Number(params.pagination) : 1;

    const perPageFormData = listData.perPage;
    const limitPerPage = perPageFormData.limit || 100;
    const defaultPerPage = perPageFormData.default || 20;
    const perPage = (params.limit && !isNaN(Number(params.limit)) && Number(params.limit) <= limitPerPage && Number(params.limit) > 1) ? Number(params.limit) : defaultPerPage;

    const authorStatus =
        (isAuthor) ?
            {
                _author_status: {
                    gt: statusManager.getDefaultStatus() - 1
                }
            } :
            (isAdmin) ?
                {} :
                {
                    _author_status: {
                        gt: statusManager.getMinStatus() - 1
                    }
                };

    if (page === ns && _id) {
        if (!pageType || (!isNaN(Number(pageType)) && Number(pageType) >= 1)) {
            return await wapp.requests.send({
                requestName: name+"FindMany",
                args: {
                    filter: {
                        _author: _id,
                        ...(query.search) ? {search: query.search} : {},
                        _operators:{
                            _status: {gt: (isAuthor || isAdmin) ? postStatusManager.getDefaultStatus() - 1 : postStatusManager.getMinStatus() - 1},
                            ...authorStatus
                        }
                    },
                    ...(sort) ? {sort} : {},
                    page: pagination,
                    perPage
                },
                req,
                res
            });
        }
    }

    if (page === ns && pageType === "deleted" && _id) {
        return await wapp.requests.send({
            requestName: name+"FindMany",
            args: {
                filter: {
                    _author: _id,
                    ...(query.search) ? {search: query.search} : {},
                    _operators:{
                        _status: {
                            lt: postStatusManager.getDefaultStatus(),
                            gt:(isAdmin) ? postStatusManager.getBannedStatus() - 1 : postStatusManager.getDeletedStatus() - 1,
                        },
                        ...authorStatus
                    }
                },
                ...(sort) ? {sort} : {},
                page: pagination
            },
            req,
            res
        });
    }

    return null;
}

export function userPageFunctions({context, appContext, postContext, name="post", nsPageProps}) {

    const ns = (name.endsWith("y")) ? name.slice(0,-1)+"ies" : name+"s";
    const N = capitalize(name);
    const Ns = capitalize(ns);

    const {wapp, res, req} = context;

    const wappResponse = res.wappResponse;
    const wappRequest = req.wappRequest;
    const route = wappResponse.route;
    const {params} = route;
    const {pageType} = params;

    //const {userStatusManager} = appContext;

    const isAdmin = wappRequest.user?._status_isFeatured;
    const utils = getUtils(context);

    const pages = {
        [ns]: (props) => {

            const selectable = typeof props.selectable !== "undefined" ? props.selectable : ({posts})=>{
                const deletionIsPossible = (posts?.length) ? posts.filter((post)=>post._status_isNotDeleted && !post._status_isFeatured) : false;
                return deletionIsPossible.length && deletionIsPossible.length === posts?.length && isAdmin;
            };
            const selectFunctions = (selectable) ? [
                {
                    role: function ({posts}) {
                        const deletionIsPossible = (posts?.length) ? posts.filter((post)=>post._status_isNotDeleted && !post._status_isFeatured) : false;
                        return deletionIsPossible.length && deletionIsPossible.length === posts?.length;
                    },
                    label: appContext.titles["dialogDelete"+Ns+"Title"],
                    func: (e, {dialog, selected})=> {

                        dialog.actions.open({
                            dialogTitle: appContext.titles["dialogDelete"+Ns+"Title"],
                            dialogContent: appContext.messages["delete"+Ns+"Question"],
                            cancelText: appContext.labels["cancel"+N+"Text"],
                            submitText: appContext.labels["delete"+Ns+"Text"],
                            onSubmit: async function () {

                                const errors = [];

                                const responses = await Promise.allSettled(selected.value.map(async (postId)=>{
                                    return await utils.sendRequest({requestName: name+"Delete", args: {_id: postId} });
                                }));

                                const deletedFiles = responses.filter((r, i)=>{
                                    const { status } = r;
                                    const resp = r.value;
                                    if (!resp?.record?._id || status !== "fulfilled"){
                                        errors.push({
                                            message: resp?.error?.message || (resp?.errors && resp.errors[0]?.message) || "Error",
                                            path: selected.value[i]
                                        });
                                        return false;
                                    }
                                    return true;
                                }).map((r)=>{return r.value});

                                if (!errors.length && deletedFiles.length === selected.value.length){

                                    setTimeout(()=>{
                                        wapp.client.history.push(wapp.wappRequest.path);
                                    },1000);

                                    return true;
                                }
                                return {errors}

                            },
                            successMessage: appContext.messages["delete"+Ns+"Success"]
                        })

                    }
                }
            ] : null;

            return (
                <Posts
                    disableAvatars={true}
                    selectable={selectable}
                    selectFunctions={selectFunctions}
                    multiple={selectable}
                    {...nsPageProps}
                    {...props}
                    name={name}
                />
            )
        }
    };

    function getDashBoardTitle({user, post, page}) {

        const isAdmin = user && user._status_isFeatured;
        const isAuthor = ((user?._id && user._id === post?._author) || (user?._id && user._id === post?._author?._id));
        const isAuthorOrAdmin = (isAdmin || isAuthor);

        if ((page === ns && !pageType) || (page === ns && !isNaN(Number(pageType)) && Number(pageType) >= 1)){
            return (isAuthor) ? appContext.titles["my"+Ns+"Title"] : appContext.titles[postContext.name+Ns+"Title"];
        }
        if (page === ns && pageType === "deleted" && isAuthorOrAdmin){
            return (isAuthor) ? appContext.titles["myDeleted"+Ns+"Title"] : appContext.titles[postContext.name+"Deleted"+Ns+"Title"];
        }

        return null;

    }

    function addMenuItems(props) {

        const {appContext} = props;
        const {menus, routes, /*userStatusManager*/} = appContext;

        return [
            {
                name: function (p) {
                    const isAuthor = ((p.user?._id && p.user._id === p.post?._author) || (p.user?._id && p.user._id === p.post?._author?._id));
                    return (isAuthor) ? menus["my"+Ns+"Menu"] : menus[postContext.name+Ns+"Menu"];
                },
                href: function (p) {
                    return (p.post?._id) ? "/"+p.post._id + routes[postContext.name+Ns+"Route"] : routes[postContext.name+Ns+"Route"];
                },
                role: function () {
                    // noinspection RedundantIfStatementJS
                    if (name === "post"){
                        return false;
                    }
                    return true;
                },
                Icon: ArticleIcon,
            },
            {
                name: function () {
                    return menus["deleted"+Ns+"Menu"];
                },
                href: function (p) {
                    return (p.post?._id) ? "/"+p.post._id + routes[postContext.name+Ns+"Route"]+"/deleted" : routes[postContext.name+Ns+"Route"]+"/deleted"
                },
                role: function (p) {
                    if (name === "post"){
                        return false;
                    }
                    const isAdmin = p.user && p.user._status_isFeatured;
                    const isAuthor = ((p.user?._id && p.user?._id === p.post?._author) || (p.user?._id && p.user?._id === p.post?._author?._id));
                    const isAuthorOrAdmin = (isAdmin || isAuthor);
                    const isPostsPage = ((p.page === ns && !p.pageType) || (p.page === ns && !isNaN(Number(p.pageType)) && Number(pageType) >= 1 ));
                    return (isPostsPage && isAuthorOrAdmin);
                },
                Icon: DeleteIcon,
                onlyIcon: true,
                featured: true,
            },
        ];
    }

    function addContentMenuItems(props) {

        const {appContext} = props;
        const {menus, routes} = appContext;

        return [
            {
                name: menus["new"+N+"Menu"],
                href: routes[name+"Route"] + "/new",
                role: function (p) {
                    if (name === "post"){
                        return false;
                    }
                    return ((p?.user?._id && p?.user?._id === p?.post?._author) || (p?.user?._id && p?.user?._id === p?.post?._author?._id));
                },
                Icon: ArticleIcon,
                disableParentRoute: true
            },
            {
                name: function (p) {
                    const isAuthor = ((p?.user?._id && p?.user?._id === p?.post?._author) || (p?.user?._id && p?.user?._id === p?.post?._author?._id));
                    return (isAuthor) ? menus["my"+Ns+"Menu"] : menus[postContext.name+Ns+"Menu"];
                },
                href: function (p) {
                    return (p?.post?._id) ? "/"+p.post._id + routes[postContext.name+Ns+"Route"] : routes[postContext.name+Ns+"Route"];
                },
                role: function (p) {
                    if (name === "post"){
                        return false;
                    }
                    const isAuthor = ((p?.user?._id && p?.user?._id === p?.post?._author) || (p?.user?._id && p?.user?._id === p?.post?._author?._id));
                    return (!isAuthor)
                },
                Icon: ArticleIcon,
            },
        ]
    }

    function getPageName({user, post, page}) {

        const isAdmin = user && user._status_isFeatured;
        const isAuthor = ((user?._id && user._id === post?._author) || (user?._id && user._id === post?._author?._id));
        const isAuthorOrAdmin = (isAdmin || isAuthor);

        if (name === "post"){
            return ((page === ns && !pageType) || (page === ns && !isNaN(Number(pageType)) && Number(pageType) >= 1 ) || (page === ns && pageType === "deleted" && isAuthorOrAdmin)) ? (isAdmin) ? page : "notFound" : null;
        }

        return ((page === ns && !pageType) || (page === ns && !isNaN(Number(pageType)) && Number(pageType) >= 1) || (page === ns && pageType === "deleted" && isAuthorOrAdmin)) ? page : null;

    }

    return {
        pages,
        getDashBoardTitle,
        addMenuItems,
        addContentMenuItems,
        getPageName
    }

}

export function adminMenu({appContext, name = "post"}) {

    const {menus, routes} = appContext;

    const N = capitalize(name);
    const ns = (name.endsWith("y")) ? name.slice(0,-1)+"ies" : name+"s";
    const Ns = capitalize(ns);

    if (name === "post"){
        return null;
    }

    return {
        name: menus[ns+"Menu"],
        Icon: ArticleIcon,
        order: 100,
        items: [
            {
                name: function () {
                    return menus[ns+"Menu"];
                },
                href: function () {
                    return routes[ns+"Route"];
                },
                Icon: ArticleOutlined,
            },
            {
                name: menus["new"+N+"Menu"],
                href: routes[name+"Route"] + "/new",
                Icon: AddIcon,
            },
            {divider: true},
            {
                name: function () {
                    return menus["deleted"+Ns+"Menu"];
                },
                href: function () {
                    return routes[ns+"Route"]+"/deleted"
                },
                Icon: DeleteIcon,
            },
            {
                name: function () {
                    return menus["banned"+Ns+"Menu"];
                },
                href: function () {
                    return routes[ns+"Route"]+"/banned"
                },
                Icon: BlockIcon,
            },
            {
                name: function () {
                    return menus["protection"+Ns+"Menu"];
                },
                href: function () {
                    return routes[ns+"Route"]+"/protected"
                },
                Icon: StarIcon,
            },
            {
                name: function () {
                    return menus["missingData"+Ns+"Menu"];
                },
                href: function () {
                    return routes[ns+"Route"]+"/missingdata"
                },
                Icon: ShortTextIcon,
            },
            {
                name: function () {
                    return menus["lowAuthorStatus"+Ns+"Menu"];
                },
                href: function () {
                    return routes[ns+"Route"]+"/lowauthorstatus"
                },
                Icon: MoodBadIcon,
            },
        ]
    };

}
