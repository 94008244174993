// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".iF1wG.vKvmn{font-size:14.4px;font-size:.9rem}.iF1wG.mxQPR{font-size:17.6px;font-size:1.1rem}.iF1wG.AeAC8{font-size:28px;font-size:1.75rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"avatar": "iF1wG",
	"small": "vKvmn",
	"list": "mxQPR",
	"big": "AeAC8"
};
module.exports = ___CSS_LOADER_EXPORT___;
