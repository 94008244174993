// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".GTtEx>div{width:100%;margin-bottom:24px}button.I-1VJ{margin-left:auto;margin-right:auto;display:block}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": "GTtEx",
	"logoutSubmit": "I-1VJ"
};
module.exports = ___CSS_LOADER_EXPORT___;
