import React, {useContext, useEffect, useState} from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Snackbar from "@mui/material/Snackbar";
import capitalize from "../../utils/capitalize";
import AnimationIcon from '@mui/icons-material/Animation';
import {WappContext} from "wapplr-react/dist/common/Wapp";

export default function getControlsMenu(p) {

    const context = useContext(WappContext);

    const {
        wapp,
        appContext,
        name = "artwork",
        req,
        post,
        additionalProps = {}
    } = p;

    const {arIsRunning} = additionalProps;

    const N = capitalize(name);

    const downloadIcon = {};

    const downloadMenu = {
        name: appContext.labels["serverRender"+N+"Label"],
        props: {
            itemTextProps: {
                secondary: appContext.labels["serverRender"+N+"LabelSecondary"],
            }
        },
        Icon: ()=>{

            const [progress, setProgress] = useState(false);
            const [message, setMessage] = useState("");

            useEffect(()=>{
                downloadIcon.actions = {
                    setProgress,
                    setMessage
                }
                return ()=>{
                    downloadIcon.actions = {
                        setProgress: async ()=>{},
                        setMessage: async ()=>{}
                    }
                }
            })

            const handleClose = async () => {
                await setMessage("")
            };

            return (
                <>
                    {(progress) ? <CircularProgress size={24}/> : <AnimationIcon />}
                    <Snackbar
                        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                        open={!!(message)}
                        onClose={handleClose}
                        message={message}
                        style={{fontSize:"14px", whiteSpace:"normal", textAlign:"center", maxWidth: "none"}}
                    />
                </>
            )
        },
        onClick: async function (e){

            e.preventDefault();
            e.stopPropagation();

            const force = window.forceRenderForAdmin;

            const options = {
                method: "post",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    force
                }),
                credentials: "same-origin",
            }

            const cookie = (req && req.headers) ? req.headers.cookie : null;
            if (cookie) {
                options.headers.cookie = cookie;
            }

            let response = null;

            await downloadIcon.actions.setProgress(true);

            try {

                response = await fetch(req.wappRequest.protocol + "://" + req.wappRequest.hostname + appContext.routes[name + "Route"] + "/" + post._id + "/render", options);

                if (response && response.json){
                    response = await response.json();
                }

                if (response && response.data && !response.errors && !response.error) {
                    response = response.data;
                }

                if (response.files){
                    response.files.forEach((url)=>{
                        window.open(url);
                    })
                }

                if (response.message) {
                    downloadIcon.actions.setMessage(response.message)
                }

            } catch (e) {
                console.log(e);
            }

            await downloadIcon.actions.setProgress(false);

        },
        forceOpenMenu: true
    }

    const requestPath = context.res.wappResponse.route.requestPath;

    return [
        ...(requestPath === "/") ? [{
            key: "fullPage",
            onClick: (e)=>{
                e.preventDefault();
                e.stopPropagation();
                const href = (post?._id) ? appContext.routes[name + "Route"] + "/widescreen/" + post._id + "/artwork" : null;
                if (href) {
                    wapp.client.history.push(href)
                }
            },
            disabled: arIsRunning,
            order: -1,
        }] : [],
        {
            key: "save",
            items: [
                {divider: true},
                downloadMenu
            ],
        },
    ]

}
