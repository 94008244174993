// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PRq-R{display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;-ms-flex-pack:center;justify-content:center;width:100%;height:100%;min-height:inherit}.PRq-R .Kt7iv{-webkit-box-shadow:none;box-shadow:none;background-color:rgba(0,0,0,0)}.q0lvE{margin-bottom:128px;padding-right:0;padding-left:0}.SzEuQ{width:100%;overflow:hidden}.SzEuQ.U1ojH{padding-top:24px}.ELwNc{overflow:hidden;-o-text-overflow:ellipsis;text-overflow:ellipsis}.HQZai{overflow:hidden;-o-text-overflow:ellipsis;text-overflow:ellipsis;margin-bottom:12px}.VXRlS{min-height:128px;padding:32px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"notFound": "PRq-R",
	"appBar": "Kt7iv",
	"container": "q0lvE",
	"titleContainer": "SzEuQ",
	"withSubtitle": "U1ojH",
	"title": "ELwNc",
	"subtitle": "HQZai",
	"content": "VXRlS"
};
module.exports = ___CSS_LOADER_EXPORT___;
