import React from "react";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AdminPanelSettings from "@mui/icons-material/AdminPanelSettings";
import InfoIcon from "@mui/icons-material/Info";

import Logo from "default-app/dist/common/components/Logo";
import Avatar from "../Avatar/me";
import {runPostTypesConfigSync} from "../../postTypes";
import getUtils from "wapplr-react/dist/common/Wapp/getUtils";

function getMenu(props = {}) {

    const {appContext, context} = props;
    const {menus, routes, /*userStatusManager*/} = appContext;

    async function onClickBefore() {
        if (appContext.template?.actions){
            appContext.template.actions.storeDrawerScrollTop();
        }
        if (appContext.template?.actions){
            await appContext.template.actions.drawerCloseNarrow();
        }
    }

    const utils = getUtils(context);

    const categoryMenuResponse = utils.getGlobalState("res.responses.categoryMenu");

    const categoryMenu = (categoryMenuResponse?.items?.length) ? categoryMenuResponse.items.map((category)=>({
        name: category.title,
        href: routes.artworksRoute + "/categories/" + category.slug,
        onClickBefore,
        props: {
            itemTextProps: {
                secondary: category.subtitle
            }
        }
    })) : [];

    const categoryDibiderN = 7;

    return [
        {
            name: menus.artworksMenu,
            href: (categoryMenu.length === 1) ? categoryMenu[0].href : routes.artworksRoute,
            Icon: function (props) {
                return (
                    <div style={{height:"24px"}}><Logo {...props} type={"icon"}/></div>
                )
            },
            onClickBefore,
            ...(categoryMenu.length > 1) ? {items: [...categoryMenu.slice(0,categoryDibiderN-1), {divider: true}, ...categoryMenu.slice(categoryDibiderN-1)]} : {
                props: {
                    itemTextProps: {
                        secondary:  categoryMenu && categoryMenu[0] && categoryMenu[0].name
                    }
                }
            }
        },
        {
            name: menus.aboutMenu,
            href: routes.documentRoute+"/about",
            role: function () {
                return false;
            },
            Icon: InfoIcon,
            onClickBefore,
        },
        {
            name: menus.loginMenu,
            href: routes.accountRoute + "/login",
            role: function (p) {
                return !(p?.user?._id) && false;
            },
            Icon: AccountCircleIcon,
            onClickBefore
        },
        {
            name: menus.dashboardMenu,
            href: function (p) {
                return (p?.user?._id) ? routes.userRoute + "/" + p.user._id : routes.userRoute;
            },
            role: function (p) {
                return p?.user?._id;
            },
            Icon: function (props) {
                return (
                    <Avatar size={"small"} {...props}/>
                )
            },
            onClickBefore
        },
        {divider: true, role: (p)=>p.user && p.user._status_isFeatured},
        {
            name: "Admin",
            role: function (p) {
                return p.user && p.user._status_isFeatured;
            },
            items: [
                ...runPostTypesConfigSync({action:"adminMenu", p: props}).filter((m)=>m).map((menu)=>{
                    menu.items.forEach((item)=>{
                        item.onClickBefore = onClickBefore;
                    });
                    return menu;
                })
            ],
            onClickBefore,
            Icon: AdminPanelSettings
        },
        {divider: true},
        {
            name: menus.logoutMenu,
            href: routes.accountRoute + "/logout",
            role: function (p) {
                return p?.user?._id;
            },
            Icon: ExitToAppIcon,
            onClickBefore
        },
    ];

}

export default getMenu;
